import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { ContentType, IHTTPBodyTreeNode, ReadonlyHTTPMessageProps } from "../models";
import { processMessage } from "../../util/MessageUtils";

const useReadOnlyHTTPMessage = ({ message, isResponse }: ReadonlyHTTPMessageProps) => {
  const [bodyString, setBodyString] = useState<string>("");
  const [bodyTree, setBodyTree] = useState<IHTTPBodyTreeNode[]>([]);
  const loading = useAppSelector((state) => state.tests.getRequestLoading);
  const [bodyType, setBodyType] = useState(ContentType.NO_CONTENT);

  useEffect(() => {
    if (message) {
      const { bodyString, bodyTree, bodyType } = processMessage(message);

      console.log("====Readonly Message====");
      console.log("message : ", message);

      setBodyTree(bodyTree);
      setBodyString(bodyString);
      setBodyType(bodyType);
    }
  }, [message]);

  return {
    loading,
    method: message && "httpMethod" in message ? message.httpMethod : "POST",
    body: {
      string: bodyString,
      setString: setBodyString,
      type: bodyType,
      setType: setBodyType,
      isReadOnly: true,
    },
    tree: {
      body: bodyTree,
      setBody: setBodyTree,
      expandAll: !isResponse,
      expandParent: isResponse,
    },
    generators: {
      enabled: false,
      body: {},
      header: {},
      query: {},
      path: {},
    },
    availableVariables: [],
    availableParameters: [],
    availableEnvVariables: [],
  };
};

export default useReadOnlyHTTPMessage;
