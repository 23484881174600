import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Button, message, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Scenarios, { Persona } from "../api/services/Scenarios";
import "../styles/ManagePersonasDialog.css";

interface ManagePersonasDialogProps {
    open: boolean;
    onClose: () => void;
    projectId: string;
    handleSaveOfPersonas: (updatedPersonas: Persona[]) => void;
}

// Extended type for combined data rows
type PersonaRow = Persona & { isNew: boolean; newIndex?: number };

const ManagePersonasDialog: React.FC<ManagePersonasDialogProps> = ({
    open,
    onClose,
    projectId,
    handleSaveOfPersonas,
}) => {
    const [personas, setPersonas] = useState<Persona[]>([]);
    const [newPersonas, setNewPersonas] = useState<Persona[]>([]);
    const [isFetchingSuggestedPersonas, setIsFetchingSuggestedPersonas] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            fetchPersonas();
        }
    }, [open]);

    const fetchPersonas = async () => {
        try {
            const response = await Scenarios.listPersonas({});
            setPersonas(response.personas || []);
            if (!response.personas || response.personas.length === 0) {
                handleAddNewRow();
            }
        } catch (error) {
            console.error("Error fetching personas:", error);
        }
    };

    const handleInputChange = (
        index: number,
        field: keyof Persona,
        value: string,
        isNew: boolean
    ) => {
        const updatedArray = isNew ? [...newPersonas] : [...personas];
        updatedArray[index] = { ...updatedArray[index], [field]: value };

        if (isNew) {
            setNewPersonas(updatedArray);
        } else {
            setPersonas(updatedArray);
        }
    };

    const handleAddNewRow = () => {
        setNewPersonas([...newPersonas, { title: "", description: "" }]);
    };

    const handleDeletePersona = (record: PersonaRow) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this persona?',
            content: 'This action cannot be undone.',
            okText: 'Yes, Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    if (record.isNew) {
                        // Remove from new personas
                        const updatedNewPersonas = newPersonas.filter((_, i) => i !== record.newIndex);
                        setNewPersonas(updatedNewPersonas);
                    } else {
                        // Call API to delete the persona
                        await Scenarios.deletePersona({ personaId: record.id! });
                        // Remove from existing personas
                        const updatedPersonas = personas.filter((persona) => persona.id !== record.id);
                        setPersonas(updatedPersonas);
                    }
                    message.success('Persona deleted successfully.');
                } catch (error) {
                    console.error('Error deleting persona:', error);
                    message.error('Failed to delete persona. Please try again.');
                }
            },
        });
    };

    const handleSuggestPersonas = async () => {
        try {
            setIsFetchingSuggestedPersonas(true);
            const response = await Scenarios.suggestPersonas({});
            if (response?.suggestedPersonas) {
                const filteredPersonas = newPersonas.filter(
                    (persona) => persona.title && persona.title.trim() !== ""
                );
                setNewPersonas([...filteredPersonas, ...response.suggestedPersonas]);
                message.success("Suggested personas added successfully.");
            } else {
                message.error("Failed to fetch suggested personas.");
            }
        } catch (error) {
            message.error("An error occurred while fetching suggested personas.");
        }
        setIsFetchingSuggestedPersonas(false);
    };

    const handleSave = () => {
        const validPersonas = personas || [];
        const validNewPersonas = newPersonas || [];

        const updatedPersonas = validPersonas.filter((persona) => persona.title);
        const addedPersonas = validNewPersonas.filter((persona) => persona.title);

        if (addedPersonas.some((persona) => !persona.title)) {
            message.error("Title must be filled out.");
            return;
        }

        const combinedPersonas = [...updatedPersonas, ...addedPersonas];
        handleSaveOfPersonas(combinedPersonas);
        onClose();
    };

    // Combine personas and newPersonas for unified rendering
    const combinedData: PersonaRow[] = [
        ...personas.map((persona) => ({ ...persona, isNew: false })),
        ...newPersonas.map((persona, index) => ({
            ...persona,
            isNew: true,
            newIndex: index,
        })),
    ];

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            render: (_: any, record: PersonaRow, index: number) => (
                <Input
                    value={record.title}
                    onChange={(e) =>
                        handleInputChange(
                            record.isNew ? record.newIndex! : index,
                            "title",
                            e.target.value,
                            record.isNew
                        )
                    }
                />
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (_: any, record: PersonaRow, index: number) => (
                <Input
                    value={record.description}
                    onChange={(e) =>
                        handleInputChange(
                            record.isNew ? record.newIndex! : index,
                            "description",
                            e.target.value,
                            record.isNew
                        )
                    }
                />
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            width: 80,
            render: (_: any, record: PersonaRow) => (
                <Button
                    icon={<DeleteOutlined />} className="secondary-button" size='small'
                    onClick={() => handleDeletePersona(record)}
                />
            ),
        },
    ];

    return (
        <Modal
            title="Define key user personas in your project..."
            open={open}
            onCancel={onClose}
            onOk={handleSave}
            width="80%"
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" className="primary-button" type="primary" onClick={handleSave}>
                    Save
                </Button>,
            ]}
        >
            <Table
                dataSource={combinedData}
                columns={columns}
                rowKey={(record: PersonaRow) =>
                    record.isNew ? `new-${record.newIndex}` : `existing-${record.id}`
                }
                pagination={false}
            />
            <Space style={{ marginTop: 16, justifyContent: "flex-start", width: "100%" }}>
                <Button onClick={handleAddNewRow}>Add New Persona</Button>
                <Button loading={isFetchingSuggestedPersonas} type="primary" className="primary-button" onClick={handleSuggestPersonas}>
                    Suggest Personas...
                </Button>
            </Space>
        </Modal>
    );
};

export default ManagePersonasDialog;