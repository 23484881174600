import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Input, List, Row, Col, Spin, Select, Typography, Skeleton } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Scenarios, {
    GetProjectDescriptionResponse,
    SuggestPersonasResponse,
    SuggestEpicsResponse,
    ListPersonasResponse,
    ListEpicsResponse,
    UserStory,
    UserStorySimple,
    Priority,
    TestScenario,
} from '../api/services/Scenarios';
import ReactQuill from 'react-quill';
import Crawl from '../api/services/Crawl';
import LocalStorageManager from '../common/LocalStorageManager';

const { TextArea } = Input;
const { Option } = Select;

interface Step {
    title: string;
    content: React.ReactNode;
    onNext?: () => Promise<void>;
    onEnter?: () => Promise<void>;
    hasNext: boolean;
    nextStepName: string;
}

const ScenarioQuickStartWizard: React.FC<{ onClose: (showCreateTestGuide: boolean) => void }> = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [projectDescription, setProjectDescription] = useState('');
    const [personas, setPersonas] = useState<string[]>([]);
    const [epics, setEpics] = useState<string[]>([]);
    const [selectedEpicId, setSelectedEpicId] = useState<number | null>(null);
    const [userStories, setUserStories] = useState<UserStory[]>([]);
    const [currentUserStory, setCurrentUserStory] = useState<UserStory | null>(null);
    const [testScenarios, setTestScenarios] = useState<TestScenario[]>([]);
    const [currentTestScenario, setCurrentTestScenario] = useState<string>('');
    const [currentTestScenarioId, setCurrentTestScenarioId] = useState<number | null>(null);
    const [selectedPersonaId, setSelectedPersonaId] = useState<number | null>(null);
    const [personasMap, setPersonasMap] = useState<{ id: number; title: string }[]>([]);
    const [loadingProjectDescription, setLoadingProjectDescription] = useState<boolean>(false);
    const [loadingPersonas, setLoadingPersonas] = useState<boolean>(false);
    const [loadingEpics, setLoadingEpics] = useState<boolean>(false);
    const [loadingUserStories, setLoadingUserStories] = useState<boolean>(false);
    const [loadingTestScenarios, setLoadingTestScenarios] = useState<boolean>(false);
    const [loadingTestScenarioDescription, setLoadingTestScenarioDescription] = useState<boolean>(false);
    const [loadingUserStoryDescription, setLoadingUserStoryDescription] = useState<boolean>(false);
    const [showManualWriteUserStoryButton, setShowManualWriteUserStoryButton] = useState<boolean>(false);
    const [showManualWriteTestScenarioButton, setShowManualWriteTestScenarioButton] = useState<boolean>(false);
    const [showEndMessage, setShowEndMessage] = useState<boolean>(false);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const userStoryQuillRef = useRef<ReactQuill | null>(null);
    const testScenarioQuillRef = useRef<ReactQuill | null>(null);

    // Trigger `onEnter` when the step changes
    useEffect(() => {
        const currentStepConfig = steps[currentStep];
        if (currentStepConfig.onEnter) {
            currentStepConfig.onEnter();
        }
    }, [currentStep]);

    const fetchTestScenarioDescription = async (retryCount = 0) => {
        try {
            setLoadingTestScenarioDescription(true);
            const response = await Scenarios.suggestTestScenarioDescription({
                scenarioId: currentTestScenarioId!
            });
            testScenarioQuillRef.current?.getEditor().setContents(JSON.parse(response.description));
        } catch (error) {
            console.error("Error fetching suggested description:", error);
            if (retryCount < 1) {
                await fetchTestScenarioDescription(retryCount + 1);
            } else {
                setShowManualWriteTestScenarioButton(true);
            }
        } finally {
            setLoadingTestScenarioDescription(false);
            setShowEndMessage(true);
        }
    };

    const fetchUserStoryDescription = async (retryCount = 0) => {
        try {
            setLoadingUserStoryDescription(true);
            const response = await Scenarios.suggestUserStoryDescription({
                title: currentUserStory!.title,
                personaId: selectedPersonaId!,
                epicId: selectedEpicId!,
            });
            setLoadingUserStoryDescription(false);
            userStoryQuillRef.current?.getEditor().setContents(JSON.parse(response.description));
            setCurrentUserStory((prev) => ({ ...prev, description: response.description }));
        } catch (error) {
            console.error("Error fetching suggested description:", error);
            if (retryCount < 1) {
                await fetchUserStoryDescription(retryCount + 1);
            } else {
                setShowManualWriteUserStoryButton(true);
            }
        } finally {
            setLoadingUserStoryDescription(false);
        }
    };

    const onCloseInternal = (showTestCreateWizard: boolean) => {
        Scenarios.upsertTestScenarioList({ scenarios: [{ id: currentTestScenarioId!, priority: Priority.P1, title: currentTestScenario, userStoryId: currentUserStory!.id, description: JSON.stringify(testScenarioQuillRef.current?.getEditor().getContents()) }] });
        onClose(showTestCreateWizard);
    }

    const handleAddPersona = () => {
        setPersonas([...personas, ""]);
        setIsNextDisabled(false);
    };

    const handleEditPersona = (index: number, value: string) => {
        const updatedPersonas = [...personas];
        updatedPersonas[index] = value;
        setPersonas(updatedPersonas);
    };

    const handleDeletePersona = (index: number) => {
        const updatedPersonas = personas.filter((_, i) => i !== index);
        setPersonas(updatedPersonas);
        setIsNextDisabled(updatedPersonas.length === 0); // Disable "Next" if no personas remain
    };

    const handleAddEpic = () => {
        setEpics([...epics, ""]);
    };

    const handleEditEpic = (index: number, value: string) => {
        const updatedEpics = [...epics];
        updatedEpics[index] = value;
        setEpics(updatedEpics);
    };


    const handleDeleteEpic = (index: number) => {
        const updatedEpics = epics.filter((_, i) => i !== index);
        setEpics(updatedEpics);
    };
    const handleScopeEpic = async (epic: string) => {
        const response = await Scenarios.upsertEpic({ title: epic });
        setSelectedEpicId(response.id);
        setCurrentStep(currentStep + 1);
    };

    const steps: Step[] = [
        {
            title: 'Wathca working on?',
            hasNext: true,
            content: (
                loadingProjectDescription ? (
                    <div>
                        <Typography.Text className="guide-text" style={{ display: "block" }}>
                            Give us a second to figure out what you are building based on your website...
                        </Typography.Text>
                        <Skeleton active paragraph={{ rows: 4 }} />
                    </div>
                ) : (
                    <div style={{ paddingTop: "8px" }}>
                        <Typography.Text className="guide-text" style={{ display: "block" }}>
                            Our chimps have been busy. We've scanned your domain and drafted a short description.
                            Have a look, edit it, but don't stress—it doesn't need to be perfect. You can always edit it later.
                        </Typography.Text>
                        <Typography.Text className="guide-text" style={{ display: "block" }}>
                            We'll use it to help you create detailed test plans.
                        </Typography.Text>
                        <TextArea
                            style={{ marginTop: "16px" }}
                            rows={8}
                            value={projectDescription}
                            onChange={(e) => {
                                const value = e.target.value;
                                setProjectDescription(value);

                                // Calculate word count and validate
                                const wordCount = value.trim().split(/\s+/).length;
                                if (wordCount < 20) {
                                    setError("Description should be at least 20 words.");
                                    setIsNextDisabled(true);
                                } else {
                                    setError(null);
                                    setIsNextDisabled(false);
                                }
                            }}
                        />
                        {error && (
                            <Typography.Text type="danger" style={{ display: "block", marginTop: "8px" }}>
                                {error}
                            </Typography.Text>
                        )}
                    </div>
                )
            ),
            onEnter: async () => {
                setLoadingProjectDescription(true);
                try {
                    let response: GetProjectDescriptionResponse = await Scenarios.getProjectDescription({});
                    if (!response.description) {
                        let domain = LocalStorageManager.getUserDomain();
                        if (domain) {
                            const url: string = domain!.startsWith("https://") ? domain! : "https://" + domain;
                            const content = await Crawl.extractContent({ url });
                            if (!content.isDevAgency) {
                                setProjectDescription(content.summary + "");
                            }
                        }
                    } else {
                        setProjectDescription(response.description);
                        const wordCount = response.description.trim().split(/\s+/).length;
                        if (wordCount < 20) {
                            setError("Description is too short. Please enter at least 20 words.");
                            setIsNextDisabled(true);
                        } else {
                            setError(null);
                            setIsNextDisabled(false);
                        }
                    }
                } finally {
                    setLoadingProjectDescription(false);
                }
            },
            onNext: async () => {
                await Scenarios.updateProjectDescription({ description: projectDescription });
            },
            nextStepName: "Define Personas"
        },
        {
            title: 'Are these your people?',
            hasNext: true,
            content: (
                <div style={{ paddingTop: "8px" }}>
                    <Typography.Text className="guide-text" style={{ display: "block" }}>
                        Did we mention the chimps? BTW, don't worry, they're virtual.
                        The good news is that they're always working to make your life easier.
                    </Typography.Text>
                    {loadingPersonas ? (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                Give us a second to figure out who your stakeholders are...
                            </Typography.Text>
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                    ) : (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                We've used your description and what we can see of your site to generate some
                                <b>suggested personas</b>.
                            </Typography.Text>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                Feel free to add more of your own, edit ours, or delete any that aren't relevant.
                            </Typography.Text>
                            <List
                                dataSource={personas}
                                renderItem={(item, index) => (
                                    <List.Item
                                        actions={[
                                            <DeleteOutlined onClick={() => handleDeletePersona(index)} />
                                        ]}
                                    >
                                        <Input
                                            value={item}
                                            onChange={(e) => handleEditPersona(index, e.target.value)}
                                        />
                                    </List.Item>
                                )}
                            />
                            <Button
                                type="dashed"
                                style={{ marginTop: "16px" }}
                                onClick={handleAddPersona}
                            >
                                Add Persona
                            </Button>
                        </div>
                    )}
                </div>
            ),
            onEnter: async () => {
                setLoadingPersonas(true);
                try {
                    const response: ListPersonasResponse = await Scenarios.listPersonas({});
                    if (response.personas) {
                        setPersonas(response.personas?.map((persona) => persona.title!));
                        setPersonasMap(response.personas?.map((persona) => ({ id: persona.id!, title: persona.title! })));
                    } else {
                        const response: SuggestPersonasResponse = await Scenarios.suggestPersonas({});
                        setPersonas(response.suggestedPersonas?.map((persona) => persona.title!));
                        setPersonasMap(response.suggestedPersonas?.map((persona) => ({ id: persona.id!, title: persona.title! })));
                    }
                    setIsNextDisabled(personas.length === 0); // Disable "Next" if no personas are loaded
                } finally {
                    setLoadingPersonas(false);
                }
            },
            onNext: async () => {
                await Scenarios.upsertPersonaList({
                    personas: personas.map((persona) => ({ title: persona })),
                });
                const response: ListPersonasResponse = await Scenarios.listPersonas({});
                if (response.personas) {
                    setPersonas(response.personas?.map((persona) => persona.title!));
                    setPersonasMap(response.personas?.map((persona) => ({ id: persona.id!, title: persona.title! })));
                }
            },
            nextStepName: "Define Epics"
        },
        {
            title: 'Epics',
            hasNext: true,
            content: (
                <div style={{ paddingTop: "8px" }}>
                    <Typography.Text className="guide-text" style={{ display: "block" }}>
                        Epics are the big features, those major projects you're working on. They help organize user stories and build out test plans.
                    </Typography.Text>
                    {loadingEpics ? (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                Imagining the big building blocks of your project...
                            </Typography.Text>
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                    ) : (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                Here are some big buckets we whipped out. You can add/edit later on. Pick one to scope out some user stories next.
                            </Typography.Text>
                            <List
                                dataSource={epics}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <Row style={{ width: '100%' }}>
                                            <Col flex="auto">
                                                <Input
                                                    value={item}
                                                    onChange={(e) => handleEditEpic(index, e.target.value)}
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    onClick={() => handleScopeEpic(item)}
                                                    className="secondary-button"
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    Scope it
                                                </Button>
                                                <Button
                                                    type="text"
                                                    danger
                                                    onClick={() => handleDeleteEpic(index)}
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    <DeleteOutlined/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                            <Button
                                type="dashed"
                                style={{ marginTop: '16px' }}
                                onClick={handleAddEpic}
                            >
                                Add Epic
                            </Button>
                        </div>
                    )}
                </div>
            ),
            onEnter: async () => {
                setLoadingEpics(true);
                try {
                    const response: ListEpicsResponse = await Scenarios.listEpics({});
                    if (response.epics) {
                        setEpics(response.epics.map((epic) => epic.title!));
                    } else {
                        const suggestResponse: SuggestEpicsResponse = await Scenarios.suggestEpics({});
                        setEpics(suggestResponse.suggestedEpics || []);
                    }
                } finally {
                    setLoadingEpics(false);
                }
            },
            nextStepName: "",
        },
        {
            title: 'User Stories',
            hasNext: false,
            content: (
                <div style={{ paddingTop: "6px" }}>
                    <Typography.Text className="guide-text" style={{ display: "block" }}>Select a persona</Typography.Text>
                    <Select
                        style={{ width: '100%' }}
                        value={selectedPersonaId || undefined}
                        placeholder="Select a Persona"
                        onChange={async (value) => {
                            console.log("Setting selectedPersonaId to", value);
                            setSelectedPersonaId(value);
                            if (selectedEpicId) {
                                setLoadingUserStories(true);
                                try {
                                    const response = await Scenarios.suggestUserStories({
                                        epicId: selectedEpicId,
                                        personaId: value,
                                    });
                                    setUserStories(response.suggestedUserStories);
                                } finally {
                                    setLoadingUserStories(false);
                                }
                            }
                        }}
                    >
                        {personasMap.map((persona) => (
                            <Option key={persona.id} value={persona.id}>
                                {persona.title}
                            </Option>
                        ))}
                    </Select>
                    {loadingUserStories ? (
                        <div style={{ paddingTop: "8px" }}>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>Dreaming up some user stories to consider...</Typography.Text>
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>) : (
                        <div style={{ paddingTop: "8px" }}>
                            {userStories.length > 0 && <Typography.Text className="guide-text" style={{ display: "block" }}>See, those chimps are pretty clever. We've highlighted a few user stories. Pick one to flesh out further with more details...</Typography.Text>}
                            <List
                                dataSource={userStories}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <Row style={{ width: '100%' }}>
                                            <Col flex="auto">
                                                <Input
                                                    value={item.title}
                                                    onChange={(e) =>
                                                        setUserStories((prev) =>
                                                            prev.map((story, i) =>
                                                                i === index ? { ...story, title: e.target.value } : story
                                                            )
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    onClick={() => handleFleshOutUserStory(item)}
                                                    className="secondary-button"
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    Flesh it out
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </div>

                    )}
                </div>
            ),
            nextStepName: ""
        },
        {
            title: 'Flesh out the user story',
            hasNext: true,
            content:
                <div style={{ paddingTop: "8px" }}>
                    <Typography.Text className="guide-text" style={{ display: "block" }}>We know drafting user stories can be painful.  That's why we've drafted an army of Chimps to help.  We're using the full context of your site and systems to draft high quality summaries and test scenarios.  You can always edit or add extra detail, but we usually get you most of the way there.</Typography.Text>
                    {loadingUserStoryDescription && (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>Fleshing out your user story...</Typography.Text>
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                    )}
                    {showManualWriteUserStoryButton && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="dashed"
                                loading={loadingUserStoryDescription}
                                onClick={() => fetchUserStoryDescription()}
                            >
                                Write for me
                            </Button>
                        </div>
                    )}
                    <ReactQuill
                        ref={userStoryQuillRef}

                        theme="snow"
                        style={{
                            height: '12em',
                            maxHeight: '12em',
                            overflowY: 'inherit',
                            marginBottom: '70px',
                            marginTop: "20px",
                            display: loadingUserStoryDescription ? 'none' : 'block',
                        }}
                    />
                </div>
            , onEnter: async () => {
                await fetchUserStoryDescription();
            },
            onNext: async () => {
                setCurrentUserStory(((prev) => ({ ...prev, description: JSON.stringify(userStoryQuillRef.current?.getEditor().getContents()), })));
                const response = await Scenarios.upsertUserStoryList({ userStories: [currentUserStory!] });
                setCurrentUserStory((prev) => ({ ...prev, id: response.ids![0] }));
            },
            nextStepName: "Define Test Scenarios"
        },
        {
            title: 'Test Scenarios',
            hasNext: false,
            content:
                <div style={{ paddingTop: "8px" }}>
                    <Typography.Text className="guide-text" style={{ display: "block" }}>We're nearly at the end.  Just one more button and you'll have your first scenario detailed.</Typography.Text>
                    {loadingTestScenarios ? (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>Coming up with scenarios to test can be exhaustive. Our chimps can do the heavy lifting for you...</Typography.Text>
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                    ) : (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>Ok, here are some recommended scenarios to consider. Pick one to detail out further.</Typography.Text>
                            <List
                                dataSource={testScenarios}
                                renderItem={(item) => (
                                    <List.Item>
                                        <Row style={{ width: '100%' }}>
                                            <Col flex="5">
                                                <Input value={item.title} />
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    className="secondary-button"
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={() => handleFleshOutTestScenario(item.title!)}
                                                >
                                                    Flesh out
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </div>
                    )}
                </div>,
            onEnter: async () => {
                setLoadingTestScenarios(true);
                try {
                    const response = await Scenarios.suggestTestScenarios({
                        userStoryId: currentUserStory!.id!,
                    });
                    setTestScenarios(response.suggestedTestScenarios);
                } finally {
                    setLoadingTestScenarios(false);
                }
            },
            nextStepName: ""
        },
        {
            title: 'Boom!',
            hasNext: false,
            content:
                <div style={{ paddingTop: "8px" }}>
                    {loadingTestScenarioDescription && (
                        <div>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>Our chimps' are busy writing up the test scenario details for you...</Typography.Text>
                            <Skeleton active paragraph={{ rows: 4 }} />
                        </div>
                    )}
                    {showManualWriteTestScenarioButton && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                loading={loadingTestScenarioDescription}
                                type="dashed"
                                onClick={() => fetchTestScenarioDescription()}
                            >
                                Write for me
                            </Button>
                        </div>
                    )} <ReactQuill
                        ref={testScenarioQuillRef}
                        theme="snow"
                        style={{
                            display: loadingTestScenarioDescription ? 'none' : 'block',
                            height: '15em',
                            maxHeight: '12em',
                            overflowY: 'inherit',
                            marginBottom: '70px',
                            marginTop: "20px"
                        }}
                    />
                    {showEndMessage && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                This quick start guide walked you through how TestChimp helps define and write high-quality:
                            </Typography.Text>
                            <ul style={{ listStyleType: 'disc', textAlign: 'left', marginLeft: '20px' }}>
                                <li>User personas</li>
                                <li>Epics</li>
                                <li>User Stories</li>
                                <li>Test scenarios</li>
                            </ul>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>
                                We've saved everything in your profile. It's easy for you to add more detail to your plans. Just hit the <strong>Scenario Tab</strong> from the navigation bar.
                            </Typography.Text>
                            <Typography.Text className="guide-text" style={{ display: "block" }}>What next?</Typography.Text>
                            <Row justify="space-around" style={{ marginTop: '10px' }}>
                                <Col>
                                    <Button type="primary" className="primary-button" onClick={() => onCloseInternal(true)}>
                                        User Journey API Test Quickstart
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" className='secondary-button' onClick={() => onCloseInternal(false)}>
                                        Start Exploring
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>,
            onEnter: async () => {
                await fetchTestScenarioDescription();
            },
            onNext: async () => {
            },
            nextStepName: ""
        }
    ];

    const handleFleshOutUserStory = async (userStory: UserStorySimple) => {
        const newStory = { ...userStory, priority: Priority.P1, description: '' };
        setCurrentUserStory(newStory);
        setCurrentStep(currentStep + 1);
    };

    const handleFleshOutTestScenario = async (testScenarioTitle: string) => {
        const newScenario: TestScenario = { title: testScenarioTitle, description: '', priority: Priority.P1, userStoryId: currentUserStory!.id };
        setCurrentTestScenario(testScenarioTitle);
        const response = await Scenarios.upsertTestScenarioList({ scenarios: [newScenario] });
        setCurrentTestScenarioId(response.ids![0]);
        const filteredScenarios = testScenarios.filter((scenario) => scenario.title !== testScenarioTitle);
        const response2 = await Scenarios.upsertTestScenarioList({ scenarios: filteredScenarios });
        setCurrentStep(currentStep + 1);
    };




    const handleNext = async () => {
        const step = steps[currentStep];
        if (step.onNext) {
            await step.onNext();
        }
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <Modal
            open={true}
            onCancel={() => onClose(false)}
            footer={
                <>
                    {steps[currentStep].hasNext && (
                        <Button
                            onClick={handleNext}
                            type="primary"
                            className="primary-button"
                            disabled={isNextDisabled}
                        >
                            {currentStep === steps.length - 1 ? 'Finish' : 'Next: ' + steps[currentStep].nextStepName}
                        </Button>
                    )}
                </>
            }
            title={steps[currentStep].title}
            width={800}
        >
            {steps[currentStep].content}
        </Modal>
    );
};

export default ScenarioQuickStartWizard;