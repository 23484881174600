import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Select, Input, Switch, Form, message } from 'antd';
import Scenarios, { Priority, SuggestTestScenarioDescriptionResponse, TestScenario, TestScenarioStatus } from '../api/services/Scenarios';
import { AutoAwesome } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface EditTestScenarioDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (testScenario: TestScenario) => void;
    testScenarioToEdit?: TestScenario; // Added for edit mode
}

const EditTestScenarioDialog: React.FC<EditTestScenarioDialogProps> = ({ open, onClose, onSave, testScenarioToEdit }) => {
    const [newTitle, setNewTitle] = useState('');
    const [newDescription, setNewDescription] = useState<string>('{}');
    const [newPriority, setNewPriority] = useState<Priority | ''>('');
    const [isFetchingSuggestDescription,setIsFetchingSuggestDescription] = useState<boolean>(false);
    const [testScenarioId, setTestScenarioId] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<TestScenarioStatus>(TestScenarioStatus.ACTIVE_TEST_SCENARIO);
    const quillRef = useRef<ReactQuill | null>(null);

    const priorities = {
        [Priority.P0]: 'P0',
        [Priority.P1]: 'P1',
        [Priority.P2]: 'P2',
        [Priority.P3]: 'P3',
    };

    useEffect(() => {
        if (testScenarioToEdit) {
            setNewTitle(testScenarioToEdit.title || '');
            setNewPriority(testScenarioToEdit.priority || '');
            setTestScenarioId(testScenarioToEdit.id);
            setNewDescription(testScenarioToEdit.description || '{}');
            setStatus(testScenarioToEdit.status || TestScenarioStatus.ACTIVE_TEST_SCENARIO);
        } else {
            setNewTitle('');
            setNewPriority('');
            setTestScenarioId(undefined);
            setNewDescription('');
            setStatus(TestScenarioStatus.ACTIVE_TEST_SCENARIO);
        }
    }, [testScenarioToEdit]);

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current?.getEditor().setContents(JSON.parse(newDescription ?? "{}"));
        }
    }, [quillRef.current]);

    const handleSave = () => {
        const testScenario: TestScenario = {
            id: testScenarioId, // Include ID for edit mode
            title: newTitle,
            description: JSON.stringify(quillRef.current?.getEditor().getContents()),
            priority: newPriority || Priority.UNKNOWN_PRIORITY,
            userStoryId: testScenarioToEdit?.userStoryId,
            status: status,
        };
        onSave(testScenario);
        onClose(); // Close dialog after saving
    };

    const handleSuggestDescription = async () => {
        try {
            setIsFetchingSuggestDescription(true);
            const response: SuggestTestScenarioDescriptionResponse = await Scenarios.suggestTestScenarioDescription({ scenarioId: testScenarioToEdit!.id!, existingDescription: JSON.stringify(quillRef.current?.getEditor().getContents()) });
            if (!response.description || response?.description?.length == 0) {
                message.error("Could not complete the description. Try again later");
            }
            quillRef.current?.getEditor().setContents(JSON.parse(response.description));
        } catch (error) {
            console.error("Error fetching suggested description:", error);
        }
        setIsFetchingSuggestDescription(false);
    };

    const handleToggleArchive = () => {
        setStatus((prevStatus) =>
            prevStatus === TestScenarioStatus.ACTIVE_TEST_SCENARIO
                ? TestScenarioStatus.DELETED_TEST_SCENARIO
                : TestScenarioStatus.ACTIVE_TEST_SCENARIO
        );
    };

    return (
        <Modal
            title="Edit Test Scenario"
            open={open}
            width={800}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" className="primary-button" onClick={handleSave}>
                    Save
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <Form.Item label="Title">
                    <Input
                        autoFocus
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                    />
                </Form.Item>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
                        <Button
                            loading={isFetchingSuggestDescription}
                            type="dashed"
                            onClick={handleSuggestDescription}
                            disabled={!newTitle || newTitle?.length === 0}
                        >
                            Write for me...
                        </Button>
                    </div>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        style={{
                            height: '12em',
                            maxHeight: '12em',
                            overflowY: 'inherit',
                            marginBottom: '70px',
                        }}
                    />
                </div>

                <Form.Item label="Priority">
                    <Select
                        style={{ width: '200px' }}
                        value={newPriority}
                        onChange={(value) => setNewPriority(value as Priority)}
                    >
                        <Select.Option value="">None</Select.Option>
                        {Object.entries(priorities).map(([value, label]) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Status">
                    <Switch
                        checked={status !== TestScenarioStatus.DELETED_TEST_SCENARIO}
                        onChange={handleToggleArchive}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        {status === TestScenarioStatus.DELETED_TEST_SCENARIO ? 'Archived' : 'Active'}
                    </span>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditTestScenarioDialog;