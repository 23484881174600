export function getBaseURIWithoutQuery(uri: string): string {
    const index = uri.indexOf('?');
    if (index !== -1) {
      return uri.substring(0, index);
    }
    return uri;
  }

  export function getDomain(email: string): string {
    if (!email.includes("@")) {
        return ""; // Return an empty string if the email format is invalid
    }

    return email.split("@")[1];
}