import { generatorsToBodyString, getGeneratorLabel, processJSONBody, processPlainBody } from "../util/MessageUtils";
import {
  ContextType,
  FieldConfig,
  FieldDataType,
  GeneratorType,
  HTTPMessageSection,
  IGenerator,
  IVariable,
  IOperationContextualVariableRecordConfig,
  PayloadLocator,
  VariableRecordConfig,
  VariableValueLocator,
  IGeneratorKV,
  IRequestConfig,
  ContentType,
  IMessageBody,
  IHTTPBodyTreeNode,
  NO_BODY_METHODS,
} from "./models";
import { isArray } from "lodash";

const getVariableValueLocator = (variable: IVariable): VariableValueLocator => {
  let ret: VariableValueLocator = {};
  if (variable.section == HTTPMessageSection.HEADER) {
    ret.headerKey = variable.locator;
  } else if (variable.section == HTTPMessageSection.BODY) {
    // TODO This needs to be handled for other body types as well.
    ret.bodyValueLocator = { jsonFieldPath: variable.locator };
  } else if (variable.section == HTTPMessageSection.PATH_PARAMS) {
    ret.templateParamKey = variable.locator;
  } else if (variable.section == HTTPMessageSection.QUERY_PARAMS) {
    ret.queryParamKey = variable.locator;
  }
  return ret;
};

export const getOperationContextualVariables = (variables: IVariable[]): IOperationContextualVariableRecordConfig[] => {
  // TODO This needs to be handled for other body types as well.
  return variables
    ?.filter((variable) => variable.operationLocator)
    .map((variable) => {
      return {
        operationLocator: variable.operationLocator!,
        payloadLocator: getPayloadLocator(variable.contextType),
        config: {
          variableName: variable.name,
          locator: getVariableValueLocator(variable),
        },
      };
    });
};

export const getPayloadLocator = (contextType: ContextType): PayloadLocator => {
  if (contextType == ContextType.REQUEST) {
    return PayloadLocator.REQUEST;
  } else if (contextType == ContextType.RESPONSE) {
    return PayloadLocator.RESPONSE;
  } else {
    return PayloadLocator.ATTRIBUTES;
  }
};

export const getContextType = (contextType: PayloadLocator): ContextType => {
  if (contextType == PayloadLocator.REQUEST) {
    return ContextType.REQUEST;
  } else if (contextType == PayloadLocator.RESPONSE) {
    return ContextType.RESPONSE;
  } else {
    return ContextType.ATTRIBUTES;
  }
};

export const getOperationNonContextualVariables = (
  variables: IVariable[],
  contextType: ContextType
): VariableRecordConfig[] => {
  return variables
    ?.filter((variable) => variable.contextType == contextType && !variable.operationLocator)
    .map((variable) => {
      return {
        variableName: variable.name,
        locator: getVariableValueLocator(variable),
      };
    });
};

export const parseRequestConfigBody = (requestConfig: IRequestConfig) => {
  let bodyString = "";
  let bodyType = ContentType.NO_CONTENT;
  let bodyTree: IHTTPBodyTreeNode[] = [];
  let bodyGenerators: IGeneratorKV = {};
  let body: IMessageBody = {};

  const httpMethod = requestConfig.httpMethod;

  /**TOCHECKLATER: forcing body to be empty for GET, HEAD, and OPTIONS */
  if (!NO_BODY_METHODS.includes(httpMethod)) {
    if (requestConfig.jsonRequestBodyConfig) {
      bodyType = ContentType.JSON;
      const wholeBodyFieldConfig = requestConfig.jsonRequestBodyConfig.fieldConfigs["$"];

      if (wholeBodyFieldConfig) {
        /**Should not be possible. But just in case */
        const wholeBodyGenerator = fieldConfigToIGenerator(wholeBodyFieldConfig);
        bodyString = getGeneratorLabel(wholeBodyGenerator);
        ({ bodyTree, bodyGenerators } = processPlainBody(bodyString, {}));
        body = { jsonBody: bodyString };
      } else {
        bodyGenerators = Object.entries(requestConfig.jsonRequestBodyConfig.fieldConfigs).reduce(
          (acc: IGeneratorKV, [key, value]) => {
            acc[key] = fieldConfigToIGenerator(value);
            return acc;
          },
          {}
        );
        bodyString = generatorsToBodyString(bodyGenerators, ContentType.JSON);
        const jsonBody = JSON.parse(bodyString);
        bodyTree = processJSONBody(jsonBody, "", "", isArray(jsonBody), 0);
        body = { jsonBody: bodyString };
      }
    } else if (requestConfig.xmlRequestBodyConfig) {
      bodyType = ContentType.XML;
      bodyGenerators["$"] = fieldConfigToIGenerator(requestConfig.xmlRequestBodyConfig.fieldConfig);
      bodyString = generatorsToBodyString(bodyGenerators, ContentType.XML);
      body = { xmlBody: bodyString };
    } else if (requestConfig.textRequestBodyConfig) {
      bodyType = ContentType.TEXT;
      bodyGenerators["$"] = fieldConfigToIGenerator(requestConfig.textRequestBodyConfig.fieldConfig);
      bodyString = generatorsToBodyString(bodyGenerators, ContentType.TEXT);
      body = { textBody: bodyString };
    } else if (requestConfig.htmlRequestBodyConfig) {
      bodyType = ContentType.HTML;
      bodyGenerators["$"] = fieldConfigToIGenerator(requestConfig.htmlRequestBodyConfig.fieldConfig);
      bodyString = generatorsToBodyString(bodyGenerators, ContentType.HTML);
      body = { htmlBody: bodyString };
    } else if (requestConfig.formDataUrlencodedRequestBodyConfig) {
      bodyType = ContentType.FORM_URL_ENCODED;
      bodyGenerators = Object.entries(requestConfig.formDataUrlencodedRequestBodyConfig.fieldConfigs).reduce(
        (acc: IGeneratorKV, [key, value]) => {
          acc[key] = fieldConfigToIGenerator(value);
          return acc;
        },
        {}
      );
      body = { httpFormUrlencodedBody: { keyValueMap: {} } }; //Dummy value
    } else if (requestConfig.formDataRequestBodyConfig) {
      bodyType = ContentType.MULTIPART_FORM;
      bodyGenerators = Object.entries(requestConfig.formDataRequestBodyConfig.fieldConfigs).reduce(
        (acc: IGeneratorKV, [key, value]) => {
          acc[key] = fieldConfigToIGenerator(value);
          return acc;
        },
        {}
      );
      body = { httpFormDataBody: { keyValueMap: {} } }; //Dummy value
    }
  }

  return { bodyString, bodyTree, bodyGenerators, body, bodyType };
};

export const fieldConfigToIGenerator = (fieldConfig: FieldConfig) => {
  let gen: IGenerator = {
    type: GeneratorType.FREEFORM,
    value: "",
    displayValue: "",
    dataType: FieldDataType.STRING,
  };
  if (fieldConfig.variableExpression) {
    gen.type = GeneratorType.FREEFORM;
    gen.value = fieldConfig.variableExpression.expression;
    gen.displayValue = fieldConfig.variableExpression.expression;
    gen.dataType = fieldConfig.fieldType;
  } else if (fieldConfig.scriptConfig) {
    gen.type = GeneratorType.SCRIPT;
    gen.value = fieldConfig.scriptConfig.script;
    gen.displayValue = fieldConfig.scriptConfig.nickname;
  } else if (fieldConfig.vocabConfig) {
    gen.type = GeneratorType.VOCABULORY;
    gen.value = fieldConfig.vocabConfig.options;
    gen.displayValue = fieldConfig.vocabConfig.nickname;
  }
  return gen;
};

export const getRequestConfig = (
  headerGenerators: IGeneratorKV,
  bodyGenerators: IGeneratorKV,
  queryParamGenerators: IGeneratorKV,
  pathParamGenerators: IGeneratorKV,
  bodyType: ContentType,
  httpMethod: string,
  variables: IVariable[]
): IRequestConfig => {
  console.log("Query Parms gens:", queryParamGenerators);

  let requestConfig: IRequestConfig = {
    headerConfig: {
      headerFields: getFields(headerGenerators),
    },
    httpMethod: httpMethod,
    queryPathParams: getFields(queryParamGenerators),
    templatePathParams: getFields(pathParamGenerators),
    variableRecordConfigs: getOperationNonContextualVariables(variables, ContextType.REQUEST),
  };

  addBodyConfig(requestConfig, bodyType, bodyGenerators);

  return requestConfig;
};

export const updateJSONBodyString = (bodyString: string, key: string, generator: IGenerator) => {
  const body = JSON.parse(bodyString);
  body[key] = getGeneratorLabel(generator);
  return JSON.stringify(body, null, 2);
};

function getField(generators: IGeneratorKV) {
  let field: FieldConfig | undefined;

  const generator = generators?.["$"];

  if (generator) {
    field = getFieldConfig(generator);
  }

  return field;
}

function getFields(generators: IGeneratorKV) {
  let field: { [key: string]: FieldConfig } = {};

  generators &&
    Object.entries(generators).forEach(([key, generator]) => {
      const fieldConfig = getFieldConfig(generator);
      if (fieldConfig) {
        field[key] = fieldConfig;
      }
    });

  return field;
}

function getFieldConfig(generator: IGenerator) {
  let fieldConfig: FieldConfig | undefined;

  switch (generator.type) {
    case GeneratorType.FREEFORM:
      fieldConfig = {
        variableExpression: {
          expression: generator.value,
        },
        fieldType: generator.dataType,
      };
      break;

    case GeneratorType.SCRIPT:
      fieldConfig = {
        scriptConfig: {
          script: generator.value,
          nickname: generator.displayValue,
        },
        fieldType: generator.dataType,
      };
      break;

    case GeneratorType.VOCABULORY:
      fieldConfig = {
        vocabConfig: {
          numberOfChoices: 1,
          options: generator.value,
          nickname: generator.displayValue,
        },
        fieldType: generator.dataType,
      };
      break;

    default:
      fieldConfig = undefined;
      console.error("Unknown generator type");
      break;
  }

  return fieldConfig;
}

function addBodyConfig(requestConfig: IRequestConfig, bodyType: ContentType | undefined, bodyGenerators: IGeneratorKV) {
  switch (bodyType) {
    case ContentType.JSON:
      requestConfig.jsonRequestBodyConfig = {
        fieldConfigs: getFields(bodyGenerators),
      };
      break;
    case ContentType.XML:
      const xmlFieldConfig = getField(bodyGenerators);
      if (xmlFieldConfig) {
        requestConfig.xmlRequestBodyConfig = {
          fieldConfig: xmlFieldConfig,
        };
      }
      break;
    case ContentType.TEXT:
      const textFieldConfig = getField(bodyGenerators);
      if (textFieldConfig) {
        requestConfig.textRequestBodyConfig = {
          fieldConfig: textFieldConfig,
        };
      }
      break;
    case ContentType.HTML:
      const htmlFieldConfig = getField(bodyGenerators);
      if (htmlFieldConfig) {
        requestConfig.htmlRequestBodyConfig = {
          fieldConfig: htmlFieldConfig,
        };
      }
      break;
    case ContentType.FORM_URL_ENCODED:
      requestConfig.formDataUrlencodedRequestBodyConfig = {
        fieldConfigs: getFields(bodyGenerators),
      };
      break;
    case ContentType.MULTIPART_FORM:
      requestConfig.formDataRequestBodyConfig = {
        fieldConfigs: getFields(bodyGenerators),
      };
      break;
    default:
      break;
  }
}
