import React, { useState, useEffect } from "react";
import { Table, Input, Button, Modal, Select, message, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Scenarios, {
    Priority,
    TestScenario,
    TestScenarioStatus,
    UserStoryWithTestScenarios,
    SuggestTestScenariosRequest,
} from "../api/services/Scenarios";
import "../styles/ManageTestScenariosDialog.css";

const { Option } = Select;

interface ManageTestScenariosDialogProps {
    open: boolean;
    onClose: () => void;
    userStoryWithScenarios: UserStoryWithTestScenarios | null;
    handleSaveOfScenarios: (updatedScenarios: TestScenario[], deletedScenarios: number[]) => void;
}

const ManageTestScenariosDialog: React.FC<ManageTestScenariosDialogProps> = ({
    open,
    onClose,
    userStoryWithScenarios,
    handleSaveOfScenarios,
}) => {
    const [scenarios, setScenarios] = useState<TestScenario[]>([]);
    const [newScenarios, setNewScenarios] = useState<TestScenario[]>([]);
    const [deletedScenarios, setDeletedScenarios] = useState<number[]>([]);
    const [userStoryId, setUserStoryId] = useState<number>(0);
    const [isFetchingScenarioSuggestions, setIsFetchingScenarioSuggestions] = useState<boolean>(false);

    useEffect(() => {
        if (userStoryWithScenarios) {
            const updatedScenarios = userStoryWithScenarios.testScenarios
                .filter((scenario) => scenario.status !== TestScenarioStatus.DELETED_TEST_SCENARIO)
                .map((scenario) => ({ ...scenario, userStoryId: userStoryWithScenarios.userStory.id! }));

            setScenarios(updatedScenarios);
            setUserStoryId(userStoryWithScenarios.userStory.id!);
            if (updatedScenarios.length === 0) {
                handleAddNewRow();
            }
        }
    }, [userStoryWithScenarios]);

    const handleInputChange = (index: number, field: keyof TestScenario, value: any, isNewScenario: boolean) => {
        const array = isNewScenario ? [...newScenarios] : [...scenarios];
        array[index] = { ...array[index], [field]: value };
        isNewScenario ? setNewScenarios(array) : setScenarios(array);
    };

    const handleAddNewRow = () => {
        setNewScenarios([
            ...newScenarios,
            { title: "", description: "", priority: Priority.P1, status: TestScenarioStatus.ACTIVE_TEST_SCENARIO, userStoryId },
        ]);
    };

    const handleDeleteRow = (index: number, scenarioId: number | undefined, title: string | undefined, isNewScenario: boolean) => {
        if (isNewScenario) {
            const updatedNewScenarios = newScenarios.filter((scenario) => scenario.title !== title);
            setNewScenarios(updatedNewScenarios);
        } else {
            const updatedScenarios = [...scenarios];
            if (scenarioId !== undefined) {
                setDeletedScenarios([...deletedScenarios, scenarioId]);
            }
            updatedScenarios[index].status = TestScenarioStatus.DELETED_TEST_SCENARIO;
            setScenarios(updatedScenarios);
        }
    };

    const handleSave = () => {
        if (newScenarios.some(scenario => !scenario.title || !scenario.priority)) {
            message.error("All fields must be filled out.");
            return;
        }

        const combinedScenarios = [...scenarios, ...newScenarios];
        handleSaveOfScenarios(combinedScenarios, deletedScenarios);
        clear();
    };

    const handleClose = () => {
        clear();
    };

    const clear = () => {
        setScenarios([]);
        setNewScenarios([]);
        setDeletedScenarios([]);
        setUserStoryId(0);
        onClose();
    };

    const handleSuggestScenarios = async () => {
        try {
            setIsFetchingScenarioSuggestions(true);
            const request: SuggestTestScenariosRequest = { userStoryId, newScenarios };
            const response = await Scenarios.suggestTestScenarios(request);

            if (response?.suggestedTestScenarios) {
                const filteredScenarios = newScenarios.filter((scenario) => scenario.title!.trim() !== "");
                setNewScenarios([...filteredScenarios, ...response.suggestedTestScenarios]);
                message.success("Suggested scenarios added successfully.");
            } else {
                message.error("Failed to fetch suggested scenarios.");
            }
        } catch (error) {
            message.error("An error occurred while fetching suggested scenarios.");
        }
        setIsFetchingScenarioSuggestions(false);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (_: any, record: TestScenario, index: number) => (
                <Input
                    value={record.title}
                    onChange={(e) =>
                        handleInputChange(index, "title", e.target.value, !scenarios.map(scenario => scenario.id).includes(record.id))
                    }
                />
            ),
        },
        {
            title: "Priority",
            dataIndex: "priority",
            key: "priority",
            width: 120,
            render: (_: any, record: TestScenario, index: number) => (
                <Select
                    value={record.priority}
                    onChange={(value) =>
                        handleInputChange(index, "priority", value, !scenarios.map(scenario => scenario.id).includes(record.id))
                    }
                    style={{ width: "100%" }}
                >
                    <Option value={Priority.P0}>P0</Option>
                    <Option value={Priority.P1}>P1</Option>
                    <Option value={Priority.P2}>P2</Option>
                    <Option value={Priority.P3}>P3</Option>
                </Select>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            width: 80,
            render: (_: any, record: TestScenario, index: number) => (
                <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteRow(index, record.id, record.title, !scenarios.map(scenario => scenario.id).includes(record.id))}
                />
            ),
        },
    ];

    // Combine existing and new scenarios into one array for the table
    const allScenarios = [
        ...scenarios.map((scenario) => ({ ...scenario, isNew: false })),
        ...newScenarios.map((scenario) => ({ ...scenario, isNew: true })),
    ];

    return (
        <Modal
            title="Manage Test Scenarios"
            open={open}
            onCancel={onClose}
            onOk={handleSave}
            width="80%"
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" className="primary-button" onClick={handleSave}>
                    Save
                </Button>,
            ]}
        >
            <Table
                columns={columns}
                dataSource={allScenarios.filter(
                    (scenario) => scenario.status !== TestScenarioStatus.DELETED_TEST_SCENARIO
                )}
                rowKey={(record) => record.id || `new-${record.title}`}  // Unique key for both existing and new scenarios
                pagination={false}
            />

            <Space style={{ marginTop: 16, justifyContent: "flex-start", width: "100%" }}>
                <Button onClick={handleAddNewRow}>Add Scenario</Button>
                <Button loading={isFetchingScenarioSuggestions} type="primary" className="primary-button" onClick={handleSuggestScenarios}>
                    Suggest Scenarios...
                </Button>
            </Space>
        </Modal>
    );
};

export default ManageTestScenariosDialog;