import {
  ContentType,
  FieldConfig,
  FieldDataType,
  GeneratorType,
  IGenerator,
  IGeneratorKV,
  IHTTPBodyTreeNode,
  IRawRequest,
  IRawResponse,
} from "../test-studio/models";
import { transform, isObject, isArray, isEmpty, set } from "lodash";

/**
 * Process all the types of messages
 * @param message
 * @returns
 */
export const processMessage = (message?: IRawRequest | IRawResponse) => {
  const headerGenerators = processKvMap(message?.headers);
  const queryParamGenerators = message && "queryParams" in message ? processKvMap(message?.queryParams) : {};
  const pathParamGenerators =
    message && "templatePathParams" in message ? processKvMap(message?.templatePathParams?.keyValueMap) : {};

  let bodyString = "";
  let bodyType = ContentType.NO_CONTENT;
  let bodyGenerators: { [key: string]: IGenerator } = {};
  let bodyTree: IHTTPBodyTreeNode[] = []; //Only applicable to JSON ATM.

  if (message?.body) {
    if ("jsonBody" in message.body) {
      const jsonString = message.body.jsonBody!;
      bodyString = jsonString;
      bodyType = ContentType.JSON;

      try {
        const body = JSON.parse(jsonString);
        bodyString = JSON.stringify(body, null, 2);
        bodyTree = processJSONBody(body, "", "", isArray(body), 0, bodyGenerators);
      } catch (e) {
        bodyGenerators = {
          $: {
            type: GeneratorType.FREEFORM,
            value: bodyString,
            displayValue: bodyString,
            dataType: FieldDataType.STRING,
          },
        };
      }
    } else if ("xmlBody" in message.body) {
      const xmlString = message.body.xmlBody!;
      bodyString = xmlString;
      bodyType = ContentType.XML;

      bodyGenerators = {
        $: {
          type: GeneratorType.FREEFORM,
          value: bodyString,
          displayValue: bodyString,
          dataType: FieldDataType.STRING,
        },
      };
    } else if ("textBody" in message.body) {
      const textString = message.body.textBody!;
      bodyString = textString;
      bodyType = ContentType.TEXT;

      bodyGenerators = {
        $: {
          type: GeneratorType.FREEFORM,
          value: bodyString,
          displayValue: bodyString,
          dataType: FieldDataType.STRING,
        },
      };
    } else if ("htmlBody" in message.body) {
      const htmlString = message.body.htmlBody!;
      bodyString = htmlString;
      bodyType = ContentType.HTML;

      bodyGenerators = {
        $: {
          type: GeneratorType.FREEFORM,
          value: bodyString,
          displayValue: bodyString,
          dataType: FieldDataType.STRING,
        },
      };
    } else if ("httpFormDataBody" in message.body) {
      bodyString = "";
      bodyType = ContentType.MULTIPART_FORM;

      bodyGenerators = processKvMap(message.body.httpFormDataBody!.keyValueMap);
    } else if ("httpFormUrlencodedBody" in message.body) {
      bodyString = "";
      bodyType = ContentType.FORM_URL_ENCODED;

      bodyGenerators = processKvMap(message.body.httpFormUrlencodedBody!.keyValueMap);
    } else {
      bodyString = "";
      bodyType = ContentType.NO_CONTENT;
      bodyGenerators = {};
    }
  }

  return {
    headerGenerators,
    queryParamGenerators,
    pathParamGenerators,
    bodyGenerators,
    bodyString,
    bodyTree,
    bodyType,
  };
};

export const processPlainBody = (bodyString: string, currentBodyGenerators: IGeneratorKV) => {
  let reference: {
    vocab: { [displayName: string]: IGenerator };
    script: { [displayName: string]: IGenerator };
  } = { vocab: {}, script: {} };

  //Generate reference only for non-full body currentGenerators
  !currentBodyGenerators["$"] &&
    Object.values(currentBodyGenerators).forEach((generator) => {
      if (generator.type == GeneratorType.VOCABULORY) {
        reference.vocab[generator.displayValue] = generator;
      } else if (generator.type == GeneratorType.SCRIPT) {
        reference.script[generator.displayValue] = generator;
      }
    });

  let bodyGenerators: { [key: string]: IGenerator } = {};
  let bodyTree: IHTTPBodyTreeNode[];

  try {
    const body = JSON.parse(bodyString);
    bodyTree = processJSONBody(
      body,
      "",
      "",
      isArray(body),
      0,
      bodyGenerators,
      isEmpty(reference) ? undefined : reference
    );
  } catch (e) {
    bodyGenerators = {
      $: {
        type: GeneratorType.FREEFORM,
        value: bodyString,
        displayValue: bodyString,
        dataType: FieldDataType.STRING,
      },
    };
    bodyTree = [];
  }

  return { bodyTree, bodyGenerators };
};

const processKvMap = (kvMap?: { [key: string]: string }) => {
  let generators: { [key: string]: IGenerator } = {};
  !isEmpty(kvMap) &&
    Object.keys(kvMap).forEach((key) => {
      generators[key] = {
        type: GeneratorType.FREEFORM,
        value: kvMap[key],
        displayValue: kvMap[key],
        dataType: FieldDataType.STRING,
      };
    });

  return generators;
};

const getGenerator = (
  value: any,
  reference?: {
    vocab: { [displayName: string]: IGenerator };
    script: { [displayName: string]: IGenerator };
  }
): IGenerator => {
  let generator: IGenerator | undefined;

  if (reference && typeof value == "string") {
    if (value.startsWith("@vocab(") && value.endsWith(")")) {
      const displayName = value.slice("@vocab(".length, -1);
      generator = reference.vocab[displayName];
    } else if (value.startsWith("@script(") && value.endsWith(")")) {
      const displayName = value.slice("@script(".length, -1);
      generator = reference.script[displayName];
    }
  }

  return (
    generator ?? {
      type: GeneratorType.FREEFORM,
      value,
      displayValue: value,
      dataType: FieldDataType.STRING,
    }
  );
};

export const processJSONBody = (
  body: object,
  path: string,
  modelPath: string,
  isParentArray: boolean,
  index: number,
  generators?: { [key: string]: IGenerator },
  reference?: {
    vocab: { [displayName: string]: IGenerator };
    script: { [displayName: string]: IGenerator };
  }
) => {
  let accumulator: IHTTPBodyTreeNode[] = [];

  if (isEmpty(body)) {
    return [];
  }

  return transform(
    body,
    (accumulator, value: any, key: string) => {
      const currentPath = path == "" ? key : isParentArray ? `${path}[${key}]` : `${path}.${key}`;
      const currentModelPath = isParentArray ? modelPath : path == "" ? key : `${modelPath}.${key}`;
      let children: IHTTPBodyTreeNode[] = [];

      if (isObject(value)) {
        children = processJSONBody(value, currentPath, currentModelPath, isArray(value), 0, generators, reference);
      } else if (generators) {
        generators[currentPath] = getGenerator(value, reference);
      }

      const node: IHTTPBodyTreeNode = {
        key: currentPath,
        modelPath: currentModelPath,
        attributeKey: key,
        attributeValue: value,
        children: children,
      };

      accumulator.push(node);
      index++;
    },
    accumulator
  );
};

export const createJsonMessageStructure = (fieldConfigs: { [key: string]: FieldConfig }): string => {
  const obj: { [key: string]: any } = {};

  for (const key in fieldConfigs) {
    const pathParts = key.split(".");
    let currentNode = obj;

    for (let i = 0; i < pathParts.length; i++) {
      const part = pathParts[i];

      if (i === pathParts.length - 1) {
        // Last part, set the value
        currentNode[part] = "";
      } else {
        // Intermediate part, create object if it doesn't exist
        if (!currentNode[part]) {
          currentNode[part] = {};
        }
        currentNode = currentNode[part];
      }
    }
  }

  return JSON.stringify(obj, null, 2);
};

export const getGeneratorLabel = (generator: IGenerator) => {
  switch (generator?.type) {
    case GeneratorType.FREEFORM:
      return generator.displayValue;
    case GeneratorType.SCRIPT:
      return `@script(${generator.displayValue})`;
    case GeneratorType.VOCABULORY:
      return `@vocab(${generator.displayValue})`;
    default:
      return "";
  }
};

/**
 * Converts generators to body string
 * @param generators
 */
export const generatorsToBodyString = (generators: IGeneratorKV, contentType: ContentType) => {
  switch (contentType) {
    case ContentType.JSON:
      let body = {};
      Object.keys(generators).forEach((key) => {
        set(body, key, getGeneratorLabel(generators[key]));
      });
      return JSON.stringify(body, null, 2);

    case ContentType.TEXT:
    case ContentType.HTML:
    case ContentType.XML:
      return generators["$"] ? getGeneratorLabel(generators["$"]) : "";
    case ContentType.FORM_URL_ENCODED:
    case ContentType.MULTIPART_FORM:
      return "";
    default:
      return "";
  }
};
