import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Google } from '@mui/icons-material';
import '../styles/homestyles.css';
import { storeUserInfoInLocalStorage, createUser } from '../api/services/User';
import FirebaseService from '../auth/Firebase'
import { UserCredential, getAdditionalUserInfo, AdditionalUserInfo, GoogleAuthProvider } from 'firebase/auth';
import { getVersionedUrl } from '../common/Util';
import LocalStorageManager from '../common/LocalStorageManager';
import { Modal } from 'antd';
import LogoName from '../icons/LogoWithNameWhiteRed.png'; // Import the logo image

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;
const IconWrapper = styled('span')({
    marginRight: '8px',
});

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string>("/sessions");

    useEffect(() => {
        async function isLoggedIn() {
            if (FirebaseService.isAuthenticated() === true) {
                const user = FirebaseService.getCurrentUser();
                console.log("Authenticated User", user)
                const userInfo = await storeUserInfoInLocalStorage();
                window.location.href = BASE_APP_URL + "/sessions";
            }
        }
        isLoggedIn();
        const urlParams = new URLSearchParams(window.location.search);
        const flow = urlParams.get('flow');
        if (flow == "replay") {
            const sessionId = urlParams.get('session_id');
            const projectId = urlParams.get('project_id');
            let redirectUrl = '/sessions';
            if (sessionId) {
                redirectUrl = `/replay?session_id=${sessionId}`;
            }
            if (projectId) {
                redirectUrl += `&project_id=${projectId}`;
            }
            setRedirectUrl(redirectUrl);
        }
    }, [])

    const GoogleSignInButton = styled(Button)({
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            background: '#f1f1f1',
        },
    });
    const GoogleSignInIcon = styled(Google)({
        marginRight: '8px',
        color: 'none'
    });

    const storeUserInfoAndRedirect = async () => {
        await storeUserInfoInLocalStorage();
        let organizationId: string = LocalStorageManager.getOrganizationId();
        let orgStatus: string = LocalStorageManager.getOrganizationStatus();
        let emailVerificationStatus: string = LocalStorageManager.getEmailVerificationStatus();
        if (emailVerificationStatus !== "EMAIL_VERIFIED") {
            setIsErrorMessage(true);
            setMessage("Your email address is not yet verified. Please check your inbox for mail with verification link");
            return;
        }

        // If no org, then redirect to create org
        if (!organizationId) {
            window.location.href = BASE_APP_URL + "/oobe";
        } else {
            if (orgStatus != "ACTIVE_ORG") {
                Modal.info({
                    title: 'Action Required',
                    content: 'Your free trial has ended. Please upgrade to premium to continue using TestChimp',
                    onOk() { window.location.href = BASE_APP_URL + "/admin-settings"; },
                });
            } else {
                window.location.href = BASE_APP_URL + redirectUrl;
            }
        }
    };

    const handleSignin = async () => {
        try {

            const user: UserCredential = await FirebaseService.signInWithEmailAndPassword(email, password);
            console.log('Sign-in successful');
            await storeUserInfoAndRedirect();
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Error signing in. Please check your credentials.")
        }
    };

    const handleGoogleSignin = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithGoogle();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result)
            if (additionalUserInfo) {
                if (additionalUserInfo.isNewUser) {
                    const currentUser = await FirebaseService.getCurrentUser();
                    if (currentUser && 'email' in currentUser && 'displayName' in currentUser) {
                        const currentUserEmail: string = typeof currentUser.email === 'string' ? currentUser.email : '';
                        const currentUserDispalyName: string = typeof currentUser.displayName === 'string' ? currentUser.displayName : '';
                        await saveGoogleSignedUpUser(currentUserEmail, currentUserDispalyName, currentUser.emailVerified, currentUser.uid)
                    }
                } else {
                    await storeUserInfoAndRedirect();
                }
            }
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to Signup User with Google");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDispalyName: string, emailVerified: boolean, uid: string) => {
        try {
            const userResponse = await createUser.signupUser(currentUserEmail, currentUserDispalyName, "", String(emailVerified), "GOOGLE", uid, "", "");
            await storeUserInfoAndRedirect();
        } catch (error) {
            console.log("Error is :", error);
            setIsErrorMessage(true);
            if (error + "" === "USER_FOR_EMAIL_ALREADY_EXISTS") {
                await storeUserInfoAndRedirect();
            } else if (error + "" === "DOMAIN_IS_PUBLIC") {
                setMessage("Please sign up with your work email address instead.");
            } else {
                setMessage("Error signing up with Google. Please try again later");
            }
        }
    }


    const handleResetPassword = async () => {
        try {
            await FirebaseService.sendPasswordResetEmail(email);
            setIsErrorMessage(false);
            setMessage("Please check your email for instructions to reset password.");
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("An error occured while sending the password reset email");
        }
    };
    let messageColor = "green";
    if (isErrorMessage) {
        messageColor = "red";
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            className="fade-in"
            padding="20px"
            sx={{
                background: '#BFBFBF'
            }}
        >
            <img src={LogoName} alt="Logo" style={{ marginBottom: '30px', width: '400px', height: 'auto' }} />
            <Box
                p={4}
                bgcolor="white"
                borderRadius={4}
                boxShadow={3}
                maxWidth={400}
                width="100%"
                textAlign="center"
            >
                <TextField id="user-email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    sx={{ marginBottom: "20px" }}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField id="user-password"
                    label="Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                    sx={{ marginBottom: "20px" }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button id="login-button" sx={{ mt: 2, paddingX: "10px", paddingY: "10x" }}
                    variant="contained" style={{ backgroundColor: (!email || !password) ? '#E0E0E0' : 'var(--secondary-color)', color: "white" }} onClick={handleSignin} disabled={!email || !password}>
                    Sign in
                </Button>
                <Typography variant="body2" sx={{ mt: 2, marginBottom: "10px" }}>
                    Or
                </Typography>
                <GoogleSignInButton fullWidth onClick={handleGoogleSignin}
                    startIcon={<IconWrapper><Google /></IconWrapper>}       >             Continue with Google
                </GoogleSignInButton>
                <div>
                    <Button variant="text" sx={{ mt: 1, textTransform: 'none' }} onClick={handleResetPassword}>Reset Password</Button>
                    <Button variant="text" sx={{ mt: 1, textTransform: 'none' }} component={Link} to={getVersionedUrl("/signup")}>Create an account</Button>
                </div>
                {isErrorMessage && (
                    <Typography color="error" variant="body2">
                        {message}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default SignIn;