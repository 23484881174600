import { Routes, Route } from "react-router-dom";
import FlowExplorer from "./explore/FlowExplorer";
import HomePage from "./home/HomePage";
import UserConfigViewer from "./admin/UserConfigViewer";
import "./App.css"; // import the CSS file
import TitleBar from "./home/TitleBar";
import { lazy, useEffect, useState } from "react";
import NotFoundView from "./common/NotFoundView";
import AdminSettings from "./admin/AdminSettings/AdminSettings";
import ProjectOverview from "./admin/AdminSettings/ProjectOverview";
import PaymentSuccess from "./admin/Billing/PaymentSuccess";
import { useSnackbar } from "./hooks/useSnackbar";
import SignUp from "./home/SignUp";
import SignIn from "./home/SignIn";
import RequireAdmin from "./auth/RequireAdmin";
import ExploreDemo from "./home/ExploreDemo";
import Overlay from "./overlays/Overlay";
import { ConfigProvider } from "antd";
import SessionFinder from "./home/SessionFinder";
import InvocationsViewer from "./home/InvocationsViewer";
import GuardsViewer from "./home/GuardsViewer";
import AuditLogViewer from "./admin/AdminSettings/AuditLog";
import AssertionStudio from "./assertion-studio/AssertionStudio";
import GuardEvaluationsViewer from "./home/GuardEvaluationsViewer";
import { Box } from "@mui/material";
import { useAppSelector } from "./app/hooks";
import SessionReplay from "./session-replay/SessionReplay";
import TestCaseFinder from "./home/AwareTestCaseViewer";
import VerifyMail from "./home/VerifyMail";
import TestExecutionViewer from "./test-studio/TestExecutionViewer";
import EpicViewer from "./home/EpicViewer";
import UserStoryViewer from "./home/UserStoryViewer";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ProjectSelection from "./admin/ProjectSettings/ProjectSelection";
import ApisViewer from "./home/ApisViewer";
import TestGeneration from "./test-generation/TestGeneration";
import VerifyReferrer from "./home/VerifyReferrer";
import ActivateReferrer from "./home/ActivateReferrer";
import OOBEPage from "./home/OOBEPage";

const TestStudio = lazy(() => import("./test-studio/TestStudio"));

function App() {
  const [isOobePage, setIsOobePage] = useState(false);

  const { showSnackbar } = useSnackbar();
  const snackBarProps = useAppSelector((state) => state.common.snackBarProps);
  const { message, severity } = snackBarProps;

  useEffect(() => {
    message && severity && showSnackbar(message, severity);
  }, [snackBarProps]);

  useEffect(() => {
    const pathname = window.location.pathname;

    // Check if the path contains "/signin" or "/signup"
    if (pathname.includes("/signin") || pathname.includes("/signup") || pathname.includes("/verify_") || pathname.includes("/activate_referrer")) {
      setIsOobePage(true);
    }
  });

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            controlItemBgActive: "#e5e6f1",
            colorSuccess: "black",
          },
        }}
      >
        <Box
          key={"flex-container"}
          position={"absolute"}
          top={0}
          left={0}
          bottom={0}
          right={0}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box key={"flex-column"} display={"flex"} flexDirection={"column"} flexGrow={1} minHeight={0}>
            <TitleBar isOobePage={isOobePage} />
            <Routes>
              <Route path="/" element={<SessionFinder />} />
              <Route path="/explore" element={<FlowExplorer />} />
              <Route path="/exploredemo" element={<ExploreDemo />} />
              <Route path="/oobe" element={<OOBEPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/verify_mail" element={<VerifyMail />} />
              <Route path="/verify_referrer" element={<VerifyReferrer />} />
              <Route path="/activate_referrer" element={<ActivateReferrer />} />
              <Route path="/audit_log" element={<AuditLogViewer />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/user-settings" element={<UserConfigViewer />} />
              <Route path="/project-settings" element={<ProjectSelection />} />
              <Route element={<RequireAdmin />}>
                <Route
                  path="/admin-settings"
                  children={[
                    <Route path="/admin-settings" element={<AdminSettings />} key="admin-settings" />,
                    <Route
                      path="/admin-settings/overview"
                      element={<ProjectOverview />}
                      key="admin-settings-overview"
                    />,
                  ]}
                />
              </Route>
              <Route path="/overlays" element={<Overlay />} />
              <Route path="/payment-setup-success" element={<PaymentSuccess />} />
              <Route path="/trackedtests" element={<TestCaseFinder />} />
              <Route path="/sessions" element={<SessionFinder />} />
              <Route path="/epics" element={<EpicViewer />} />
              <Route path="/user_stories" element={<UserStoryViewer />} />
              <Route
                path="/replay"
                element={
                  <DndProvider backend={HTML5Backend}>
                    <SessionReplay />
                  </DndProvider>
                }
              />
              <Route path="/invocations" element={<InvocationsViewer />} />
              <Route path="/apis" element={<ApisViewer />} />
              <Route path="/assertion-studio" element={<AssertionStudio />} />
              <Route path="/guard_evaluations" element={<GuardEvaluationsViewer />} />
              <Route path="/guards" element={<GuardsViewer />} />
              <Route path="/test-studio" element={<TestStudio />} />
              <Route path="/test-execution" element={<TestExecutionViewer />} />
              <Route path="/test-generation" element={<TestGeneration />} />
              <Route path="*" element={<NotFoundView />} />
            </Routes>
          </Box>
        </Box>
      </ConfigProvider>
    </>
  );
}

export default App;
