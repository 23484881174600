import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { HTTPMessageSection, IGenerator, IStep, IVariable } from "../models";
import { dropVariableToField, setBodyString, setGenerator } from "../../features/testsSlice";
import { debounce } from "lodash";
import { updateJSONBodyString } from "../Utills";

const useGeneratorTextField = () => {
  const dispatch = useAppDispatch();

  const selectedStep = useAppSelector((state) => state.tests.selectedStep);
  const requestGenBodyString = useAppSelector((state) => state.tests.requestGenBodyString);

  const debouncedSetBodyString = debounce(
    async (step: IStep, currentBodyString: string, key: string, newGenerator: IGenerator) => {
      const newBodyString = updateJSONBodyString(currentBodyString, key, newGenerator);
      dispatch(
        setBodyString({
          step: step,
          isResponse: false,
          prefix: "$",
          bodyString: newBodyString,
        })
      );
    },
    300
  );

  const handleAddVariable = (section: HTTPMessageSection, key: string, variable: IVariable) => {
    dispatch(dropVariableToField({ section, key, variable }));
  };

  const handleUpdate = (section: HTTPMessageSection, key: string, generator: IGenerator) => {
    dispatch(
      setGenerator({
        step: selectedStep,
        key,
        section,
        generator,
      })
    );

    if (section == HTTPMessageSection.BODY) {
      const currentBodyString = requestGenBodyString[selectedStep!.id]?.["$"];
      debouncedSetBodyString(selectedStep!, currentBodyString, key, generator);
    }
  };

  return { handleAddVariable, handleUpdate };
};

export default useGeneratorTextField;
