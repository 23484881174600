import { AwareTestType, ITestName, ITestNameWithId } from "../../test-studio/models";
import client from "../client";

export interface SummarizeUrlRequest{
    url:string;
}

export interface SummarizeUrlResponse{
    summary?:string;
    isDevAgency?:boolean;
}

const Crawl = {
    extractContent: async (request: SummarizeUrlRequest): Promise<SummarizeUrlResponse> => {
        const response = await client.post("/crawl/summarize_url", request);
        return response.data;
    }
}

export default Crawl;