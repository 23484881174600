import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Table, Button, Input, Modal, Dropdown, Menu, Upload, Tooltip } from "antd";
import { RootState } from "../app/store"; // Adjust the import based on your store location
import { OasConfig, OperationWithDetail } from "../models/config/models";
import {
  ignoreApi,
  listOperationsWithDetails,
  setOasConfig,
  setOasFile,
  setSelectedResource,
  setSelectedResourceName,
  updateResourceConfigs,
} from "../features/apisSlice";
import { listEnvironments, listProjects, listResources, setSelectedProject } from "../features/commonSlice";
import { useAppDispatch } from "../app/hooks";
import { Paper, ThemeProvider, Typography } from "@mui/material";
import { useSnackbar } from "../hooks/useSnackbar";
import yaml from "js-yaml";
import { UploadFile } from "antd/es/upload/interface";
import { generateUniqueID } from "../util";
import SessionStorageManager from "../common/SessionStorageManager";
import { RcFile } from "antd/lib/upload";
import theme from "../common/theme";
import { OperationToTestCaseRelation, TestCreationSource } from "../test-studio/models";
import TestGenerationPopup from "../test-generation/components/TestGenerationPopup";
import { openGenerationPopup } from "../features/testGenerationSlice";
import UserJourneyWizard, { UserJourneyWizardRef } from "../common/UserJourneyWizard";
import { UserJourneyType } from "../api/services/Treatments";

const { Option } = Select;

const ApisViewer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { selectedResource, selectedResourceName, oasFile, operations, oasConfig } = useSelector(
    (state: RootState) => state.apis
  );
  const { selectedProject, projects, resources, environments } = useSelector((state: RootState) => state.common);
  const { showSnackbar } = useSnackbar();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [urlInput, setUrlInput] = useState<string>("");
  const [file, setFile] = useState<UploadFile | null>(null);
  const [isProgressWizardVisible, setProgressWizardVisible] = useState<boolean>(false);
  const wizardRef = useRef<UserJourneyWizardRef>(null);
  const [isLoadingSpecFromFile, setIsLoadingSpecFromFile] = useState<boolean>(false);
  useEffect(() => {
    dispatch(listProjects());
  }, [dispatch]);

  useEffect(() => {
    if (selectedProject) {
      dispatch(listEnvironments());
      dispatch(listResources({}));
      if (!sessionStorage.getItem('isGenerateTestsProgressWizardClosed')) {
        setProgressWizardVisible(true);
        wizardRef.current?.refresh();
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    if (resources?.length > 0) {
      dispatch(setSelectedResource(resources[0].id));
      dispatch(setSelectedResourceName(resources[0].name));
    }
  }, [resources]);

  useEffect(() => {
    if (projects?.length > 0) {
      dispatch(setSelectedProject(projects[0].id));
    }
  }, [projects]);

  useEffect(() => {
    dispatch(listOperationsWithDetails({}));
  }, [selectedResource]);

  // Handle project change
  const handleProjectChange = async (projectId: string) => {
    await dispatch(setSelectedProject(projectId));
  };

  const handleLoadFromSwagger = async (fileName: string, content: string, isYaml: boolean) => {
    try {
      const swaggerData = isYaml ? yaml.dump(yaml.load(content)) : content;
      await dispatch(setOasConfig({ fileName: fileName, config: swaggerData }));
      await dispatch(updateResourceConfigs({}));
      Modal.success({
        title: "Success",
        content: "The OpenApi spec was successfully uploaded.",
      });
      dispatch(listOperationsWithDetails({}));
    } catch (error) {
      console.error("Error loading swagger", error);
      showSnackbar("Error loading Swagger file");
    }
  };

  const loadSpecFromUrl = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    try {
      const response = await fetch(urlInput);
      if (!response.ok) {
        showSnackbar("Failed to fetch Swagger file");
        return;
      }
      const contentType = response.headers.get("content-type") || "";
      const isYaml = contentType.includes("yaml") || contentType.includes("yml");
      const content = isYaml ? await response.text() : JSON.stringify(await response.json());
      console.log("Res", response);
      console.log(content);
      await handleLoadFromSwagger(urlInput, content, isYaml);
    } catch (error) {
      console.error("Error loading swagger", error);
      showSnackbar("Error loading Swagger file");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const handleCloseProgressWizard = () => {
    console.log('Wizard closed');
    setProgressWizardVisible(false);
    sessionStorage.setItem('isGenerateTestsProgressWizardClosed', 'true');
  };

  const handleFileContent = async (file: RcFile) => {
    setIsLoadingSpecFromFile(true);
    const reader = new FileReader();

    // Create a promise to handle file reading asynchronously
    const readFilePromise = new Promise<string>((resolve, reject) => {
      reader.onload = () => {
        const content = reader.result as string;
        resolve(content);
      };
      reader.onerror = () => reject(new Error("Error reading file"));
    });

    reader.readAsText(file);

    try {
      const content = await readFilePromise;
      const isYaml = file.type.includes("yaml") || file.type.includes("yml");

      // Process the content based on its type
      await handleLoadFromSwagger(file.name, content, isYaml);
    } catch (error) {
      console.error("Error processing file", error);
      // Handle errors as needed
    } finally {
      setIsLoadingSpecFromFile(false);
    }

    // Prevent upload
    return false;
  };

  // Handle resource change and fetch operations
  const handleResourceChange = async (resourceId: number) => {
    await dispatch(setSelectedResource(resourceId));
    const resource = resources.find((r) => r.id === resourceId);
    if (resource) {
      await dispatch(setSelectedResourceName(resource.name));
    }
    dispatch(listOperationsWithDetails({}));
  };

  const onSwaggerUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrlInput(e.target.value);
  };

  // Handle actions
  const handleGenerateApiTests = (record: OperationWithDetail) => {
    dispatch(openGenerationPopup({ operationId: Number(record.operationId) }));
  };

  const handleIgnoreFlow = (record: OperationWithDetail) => {
    dispatch(ignoreApi({ operationId: record.operationId }));
  };

  const handleViewExecutionSummary = (record: OperationWithDetail, environment: string) => {
    SessionStorageManager.setOverlayQueries([
      {
        id: generateUniqueID(),
        filters: [
          {
            key: "entryResourceName",
            operator: "EQUALS",
            value: selectedResourceName!,
          },
          {
            key: "entryDeducedName",
            operator: "EQUALS",
            value: record.name,
          },
          {
            key: "environment",
            operator: "EQUALS",
            value: environment,
          },
        ],
        time_window: {
          relative_window: "86400s",
        },
        color: "#FF6900",
      },
    ]);
    window.open("/overlays", "_blank");
  };

  const renderActionsMenu = (record: OperationWithDetail) => (
    <Menu>
      <Menu.SubMenu key="view_summary" title="View Execution Summary">
        {environments?.map((env) => (
          <Menu.Item key={env} onClick={() => handleViewExecutionSummary(record, env)}>
            {env}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item key="ignore_flow" onClick={() => handleIgnoreFlow(record)}>
        Ignore flow
      </Menu.Item>
      {record.name.startsWith("/") && (
        <Menu.Item key="generate_tests" onClick={() => handleGenerateApiTests(record)}>
          Generate API tests...
        </Menu.Item>
      )}
    </Menu>
  );

  const truncateWithTooltip = (text: string | undefined, maxLength: number) => {
    if (!text) {
      return "";
    }
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <Tooltip title={text}>
        <span>{maxLength < 0 ? text : text.substring(0, maxLength) + "..."}</span>
      </Tooltip>
    );
  };

  // Define columns for the operations table
  const columns = [
    {
      title: "Operation Name",
      dataIndex: "name",
      key: "name",
      width: 500,
      render: (text: string) => <Typography variant="body2">{text}</Typography>,
    },
    {
      title: "Test Count",
      dataIndex: "numTestsIncludedIn",
      key: "numTestsIncludedIn",
      width: 300,
      render: (text: string, record: OperationWithDetail) => (
        <Button
          type="link"
          onClick={() => {
            const uid = generateUniqueID();
            SessionStorageManager.setTestFindContext(uid, {
              query: {
                operationId: record.operationId,
                relation: OperationToTestCaseRelation.INCLUDED_IN_TEST,
              },
            });
            window.open("/trackedtests?uid=" + uid + "&projectId=" + selectedProject, "_blank");
          }}
        >
          Included in {text} Tests
        </Button>
      ),
    },
    {
      title: "Gen Test Count",
      dataIndex: "numGeneratedApiTests",
      key: "numGeneratedApiTests",
      width: 300,
      render: (text: string, record: OperationWithDetail) => (
        <Button
          type="link"
          onClick={() => {
            const uid = generateUniqueID();
            SessionStorageManager.setTestFindContext(uid, {
              query: {
                operationId: record.operationId,
                testCreationSource: TestCreationSource.AI_GENERATED,
                relation: OperationToTestCaseRelation.IS_OPERATION_UNDER_TEST,
              },
            });
            window.open("/trackedtests?uid=" + uid + "&projectId=" + selectedProject, "_blank");
          }}
        >
          {text} Generated Tests
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (text: string, record: OperationWithDetail) => (
        <Dropdown overlay={renderActionsMenu(record)} trigger={["click"]}>
          <Button>Actions</Button>
        </Dropdown>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "calc(100%-1000px)",
      render: (text: string) => truncateWithTooltip(text, 250),
    },
  ];

  return (
    <div className="top-container fade-in">
      <ThemeProvider theme={theme}>
        {isProgressWizardVisible && (
          <div className="flex-row progress-wizard-container">
            <UserJourneyWizard
              ref={wizardRef}
              userJourney={UserJourneyType.GENERATE_API_TESTS_USER_JOURNEY}
              onClose={handleCloseProgressWizard}
            />
          </div>
        )}
        <div id="test-filter-panel">
          <div style={{ marginBottom: "20px" }}>
            {/* Project Select */}
            <div style={{ padding: "10px", display: "flex", alignItems: "center" }}>
              <Select
                style={{ width: "50%", marginRight: "10px" }}
                value={selectedProject}
                placeholder="Select Project"
                onChange={handleProjectChange}
              >
                {projects?.map((project: any) => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
              </Select>

              {/* Resource Select */}
              <Select
                style={{ width: "50%" }}
                value={selectedResource}
                placeholder="Select Resource"
                onChange={handleResourceChange}
              >
                {resources?.map((resource: any) => (
                  <Option key={resource.id} value={resource.id}>
                    {resource.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ padding: "10px", display: "flex", alignItems: "center" }}>
            <Input
              value={!oasFile ? "No OpenAPI spec specified" : "OpenAPI Spec specified from " + oasFile}
              disabled={true}
              onChange={onSwaggerUrlChange}
              style={{ flex: 1, marginRight: "10px" }}
            />
            <Tooltip title={selectedResourceName ? "" : "Select a resource first"} placement="top">
              <Button
                className="secondary-button"
                onClick={loadSpecFromUrl}
                disabled={!selectedResourceName}
                style={{ marginRight: "10px" }}
              >
                Load Spec from URL...
              </Button>
            </Tooltip>
            <Tooltip title={selectedResourceName ? "" : "Select a resource first"} placement="top">
              <Upload
                accept=".json, .yaml, .yml"
                showUploadList={false}
                disabled={!selectedResourceName}
                beforeUpload={handleFileContent}
              >
                <Button loading={isLoadingSpecFromFile} className="secondary-button" disabled={!selectedResourceName}>
                  Load from File...
                </Button>
              </Upload>
            </Tooltip>
          </div>
        </div>
        <Paper id="testcase-container">
          <Table
            columns={columns}
            dataSource={operations}
            rowKey={(record) => record.operationId} // Ensure operationId is unique
            pagination={{
              pageSize: 50,
              showSizeChanger: true, // Optional: Allows users to change page size
              pageSizeOptions: ["10", "20", "50", "100"], // Optional: Defines the available page sizes
            }}
          />
        </Paper>
        <Modal title="Enter URL" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Input placeholder="Enter Swagger file URL" value={urlInput} onChange={(e) => setUrlInput(e.target.value)} />
        </Modal>
        <TestGenerationPopup />
      </ThemeProvider>
    </div>
  );
};

export default ApisViewer;
