import React from "react";
import ComboTextField from "./ComboTextField";
import { FieldDataType, GeneratorType, HTTPMessageSection, IGenerator, IVariable } from "../models";

interface DraftGeneratorTextFieldProps {
  fieldKey: string;
  section: HTTPMessageSection;
  generator: IGenerator;
  setGenerator: (generator: IGenerator) => void;
  variables: IVariable[];
  parameters: String[];
  envVariables: String[];
}

const DraftGeneratorTextField: React.FC<DraftGeneratorTextFieldProps> = (props) => {
  const handleAddVariable = (section: HTTPMessageSection, fieldKey: string, variable: IVariable) => {
    props.setGenerator({
      type: GeneratorType.FREEFORM,
      value: `${props.generator.value}{{${variable.name}}}`,
      displayValue: `${props.generator.value}{{${variable.name}}}`,
      dataType: FieldDataType.STRING,
    });
  };

  const handleUpdate = (section: HTTPMessageSection, key: string, generator: IGenerator) => {
    props.setGenerator(generator);
  };

  return <ComboTextField {...props} handleAddVariable={handleAddVariable} handleUpdate={handleUpdate} />;
};

export default DraftGeneratorTextField;
