import client from "../client";

export enum UserJourneyType{
    UNKNOWN_USER_JOURNEY_TYPE="UNKNOWN_USER_JOURNEY_TYPE",
    EXPORT_TEST_USER_JOURNEY="EXPORT_TEST_USER_JOURNEY",
    CREATE_TEST_SCENARIO_USER_JOURNEY="CREATE_TEST_SCENARIO_USER_JOURNEY",
    GENERATE_API_TESTS_USER_JOURNEY="GENERATE_API_TESTS_USER_JOURNEY",
}

export interface ListCompletedUserActionsRequest{
    userJourneyType:UserJourneyType;
}


export interface ListCompletedUserActionsResponse{
    exportTestActions?:ExportTestActions;
    createTestScenariosActions?:CreateTestScenariosActions;
    generateApiTestsActions?:GenerateApiTestsActions;
}

export interface ExportTestActions{
    createProject:boolean;
    captureSession:boolean;
    createTest:boolean;
    exportTest:boolean;
}

export interface CreateTestScenariosActions{
    createProject:boolean;
    setProjectDescription:boolean;
    definePersonas:boolean;
    createEpic:boolean;
    createUserStory:boolean;
    createTestScenario:boolean;
}

export interface GenerateApiTestsActions{
    createProject:boolean;
    uploadOpenapiSpec:boolean;
    createTest:boolean;
    generateTests:boolean;
}

const Treatments = {
  listCompletedUserActions: async (request: ListCompletedUserActionsRequest) : Promise<ListCompletedUserActionsResponse> => {
    const response = await client.post("treatment/list_completed_user_actions", request);
    return response.data;
  },
};

export default Treatments;
