import React, { useState } from "react";
import { Modal, Input, Select, Typography, Button, Space, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Project } from "../../../api";
import USER_ROLES from "../Constants/UserRoles";

const { Option } = Select;

const AddUser = ({ open, handleClose, project }) => {
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({
    email: "",
    role: USER_ROLES.PROJECT_VIEWER.value,
  });

  const onAddToProjectHandler = async () => {
    console.log(user);
    setSaving(true);

    const inviteUserRequest = {
      email: user.email,
      project_id: project.projectId,
      user_project_role: user.role,
    };

    const res = await Project.inviteUser(inviteUserRequest);
    if (!res.error) {
      message.success("User added to project");
      setSaving(false);
      handleClose();
    } else {
      message.error("Adding user failed. Please try again.");
      setSaving(false);
      console.error(res.error);
    }
  };

  return (
    <Modal
      title="Add User"
      visible={open}
      onCancel={handleClose}
      footer={null}
      centered
    >
      <Typography.Text strong>Project Details</Typography.Text>
      <Input
        style={{ marginTop: "1rem" }}
        placeholder="Project Name"
        value={project?.projectName}
        disabled
      />
      <Typography.Text strong style={{ marginTop: "1rem", display: "block" }}>
        User Details
      </Typography.Text>
      <Input
        style={{ marginTop: "1rem" }}
        placeholder="User Email"
        value={user.email}
        onChange={(e) =>
          setUser({
            ...user,
            email: e.target.value,
          })
        }
      />
      <Select
        style={{ marginTop: "1rem", width: "100%" }}
        placeholder="Select Role"
        value={user.role}
        onChange={(value) =>
          setUser({
            ...user,
            role: value,
          })
        }
      >
        <Option value={USER_ROLES.PROJECT_VIEWER.value}>
          {USER_ROLES.PROJECT_VIEWER.label}
        </Option>
        <Option value={USER_ROLES.PROJECT_EDITOR.value}>
          {USER_ROLES.PROJECT_EDITOR.label}
        </Option>
      </Select>
      <Space style={{ marginTop: "1.5rem", display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          type="primary" className="primary-button"
          loading={saving}
          icon={saving ? <LoadingOutlined /> : null}
          onClick={onAddToProjectHandler}
        >
          Add to Project
        </Button>
      </Space>
    </Modal>
  );
};

export default AddUser;