import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Select, Input, Row, Col, Form, message, Spin } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import Scenarios, { Priority, SuggestUserStoryDescriptionResponse, UserStory, UserStoryStatus } from '../api/services/Scenarios';

interface PersonaMap {
    [key: number]: string;
}

interface MilestoneMap {
    [key: number]: string;
}

interface SprintMap {
    [key: number]: string;
}

interface UpsertUserStoryDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (userStory: UserStory) => void;
    personas: PersonaMap;
    milestones: MilestoneMap;
    sprints: SprintMap;
    epicId: number;
    userStoryToEdit?: UserStory;
}

const UpsertUserStoryDialog: React.FC<UpsertUserStoryDialogProps> = ({ open, onClose, onSave, personas, milestones, sprints, epicId, userStoryToEdit }) => {
    const [newTitle, setNewTitle] = useState('');
    const [newDescription, setNewDescription] = useState<string | undefined>('');
    const [newPersonaId, setNewPersonaId] = useState<number | ''>('');
    const [newMilestoneId, setNewMilestoneId] = useState<number | ''>('');
    const [newSprintId, setNewSprintId] = useState<number | ''>('');
    const [newPriority, setNewPriority] = useState<Priority | ''>('');
    const [newStatus, setNewStatus] = useState<UserStoryStatus>(UserStoryStatus.UNKNOWN_USER_STORY_STATUS);
    const [userStoryId, setUserStoryId] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false); // For handling loading state for the "Write for me..." button
    const quillRef = useRef<ReactQuill | null>(null);

    const priorities = {
        [Priority.P0]: 'P0',
        [Priority.P1]: 'P1',
        [Priority.P2]: 'P2',
        [Priority.P3]: 'P3',
    };

    const statuses = {
        [UserStoryStatus.COMPLETED]: 'Completed',
        [UserStoryStatus.DELETED_USER_STORY]: 'Archived',
        [UserStoryStatus.IN_DEVELOPMENT]: 'In Development',
        [UserStoryStatus.IN_SCOPING]: 'In Scoping',
        [UserStoryStatus.IN_TESTING]: 'In Testing',
    };

    useEffect(() => {
        if (userStoryToEdit) {
            setNewTitle(userStoryToEdit.title || '');
            setNewPersonaId(userStoryToEdit.primaryPersonaId!);
            setNewMilestoneId(userStoryToEdit.milestoneId || '');
            setNewSprintId(userStoryToEdit.sprintId || '');
            setNewPriority(userStoryToEdit.priority || '');
            setNewStatus(userStoryToEdit.status || UserStoryStatus.UNKNOWN_USER_STORY_STATUS);
            setUserStoryId(userStoryToEdit.id);
            setNewDescription(userStoryToEdit.description || '');
        } else {
            setNewTitle('');
            setNewPersonaId('');
            setNewMilestoneId('');
            setNewStatus(UserStoryStatus.IN_SCOPING);
            setNewSprintId('');
            setNewDescription('');
            setNewPriority(Priority.P1);
            setUserStoryId(undefined);
        }
    }, [userStoryToEdit]);

    useEffect(() => {
        if (quillRef.current) {
            try {
                quillRef.current?.getEditor().setContents(JSON.parse(newDescription ?? "{}"));
            } catch (error) {
                quillRef.current?.getEditor().setContents(JSON.parse("{}"));
            }
        }
    }, [quillRef.current, userStoryToEdit]);

    const handleSave = () => {
        const userStory: UserStory = {
            id: userStoryId, // Include ID for edit mode
            title: newTitle,
            description: JSON.stringify(quillRef.current?.getEditor().getContents()),
            primaryPersonaId: newPersonaId || undefined,
            milestoneId: newMilestoneId || undefined,
            sprintId: newSprintId || undefined,
            epicId: epicId,
            status: newStatus,
            priority: newPriority || Priority.UNKNOWN_PRIORITY,
        };
        onSave(userStory);
        onClose(); // Close dialog after saving
    };

    const handleSuggestDescription = async () => {
        setIsLoading(true);
        try {
            const response: SuggestUserStoryDescriptionResponse = await Scenarios.suggestUserStoryDescription({
                title: newTitle,
                existingDescription: JSON.stringify(quillRef.current?.getEditor().getContents()),
                personaId: newPersonaId == '' ? 0 : newPersonaId,
                epicId: epicId,
            });

            if (!response.description || response?.description?.length == 0) {
                message.error("Could not complete the description. Try again later");
            }
            quillRef.current?.getEditor().setContents(JSON.parse(response.description));
        } catch (error) {
            console.error("Error fetching suggested description:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            visible={open}
            title="Upsert User Story"
            width={800}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" className="primary-button" onClick={handleSave}>
                    Save
                </Button>,
            ]}
        >
            <Form layout="vertical">
                {/* Persona Selection */}
                <Row>
                    <Col span={24}>
                        <Form.Item label="Persona">
                            <Select
                                value={newPersonaId ? personas[newPersonaId] : undefined} // Map ID to name
                                onChange={(selectedValue) => {
                                    const personaId = Number(Object.keys(personas).find((key) => personas[Number(key)] === selectedValue));
                                    setNewPersonaId(personaId);
                                }}
                                placeholder="Select Persona"
                            >
                                {Object.entries(personas).map(([id, name]) => (
                                    <Select.Option key={id} value={name}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Title Input */}
                <Row>
                    <Col span={24}>
                        <Form.Item label="Title" required>
                            <Input
                                value={newTitle}
                                onChange={(e) => setNewTitle(e.target.value)}
                                placeholder="Enter User Story Title"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Description Editor */}
                <Row>
                    <Col span={24}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                                type="dashed"
                                style={{ alignSelf: 'flex-end' }}
                                onClick={handleSuggestDescription}
                                loading={isLoading}
                                disabled={!newTitle || newTitle.length === 0}
                            >
                                Write for me...
                            </Button>
                            <ReactQuill
                                ref={quillRef}
                                theme="snow"
                                style={{
                                    height: '12em',
                                    maxHeight: '12em',
                                    overflowY: 'inherit',
                                    marginBottom: '70px',
                                    marginTop: "20px"
                                }}
                            />

                        </div>
                    </Col>
                </Row>

                {/* Priority, Status, Sprint and Milestone */}
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Priority">
                            <Select
                                value={newPriority}
                                onChange={(value) => setNewPriority(value)}
                                placeholder="Select Priority"
                            >
                                {Object.entries(priorities).map(([value, label]) => (
                                    <Select.Option key={value} value={value}>
                                        {label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Status">
                            <Select
                                value={newStatus}
                                onChange={(value) => setNewStatus(value)}
                                placeholder="Select Status"
                            >
                                {Object.entries(statuses).map(([value, label]) => (
                                    <Select.Option key={value} value={value}>
                                        {label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Sprint">
                            <Select
                                value={newSprintId}
                                onChange={(value) => setNewSprintId(value)}
                                placeholder="Select Sprint"
                            >
                                {Object.entries(sprints).map(([id, name]) => (
                                    <Select.Option key={id} value={Number(id)}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Milestone">
                            <Select
                                value={newMilestoneId}
                                onChange={(value) => setNewMilestoneId(value)}
                                placeholder="Select Milestone"
                            >
                                {Object.entries(milestones).map(([id, name]) => (
                                    <Select.Option key={id} value={Number(id)}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default UpsertUserStoryDialog;