import { Box } from "@mui/material";
import React, { memo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { FieldDataType, GeneratorType, HTTPMessageSection, IGenerator, IVariable } from "../models";
import { Mentions } from "antd";
import { MentionsRef } from "antd/es/mentions";
import { getGeneratorLabel } from "../../util/MessageUtils";

interface ComboTextFieldProps {
  fieldKey: string;
  section: HTTPMessageSection;
  generator: IGenerator;
  variables: IVariable[];
  parameters: String[];
  envVariables: String[];
  handleAddVariable: (section: HTTPMessageSection, key: string, variable: IVariable) => void;
  handleUpdate: (section: HTTPMessageSection, key: string, generator: IGenerator) => void;
}

const ComboTextField: React.FC<ComboTextFieldProps> = ({
  fieldKey,
  section,
  generator,
  variables,
  parameters,
  envVariables,
  handleAddVariable,
  handleUpdate,
}) => {
  const mentionsRef = useRef<MentionsRef>(null); // Typed ref for Mentions component
  const [cursorPosition, setCursorPosition] = useState(0); // To track the cursor position

  const onDrop = (item: IVariable) => {
    handleAddVariable(section, fieldKey, item);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "variable",
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  if (isActive && mentionsRef.current) {
    mentionsRef.current?.textarea?.setSelectionRange(
      getGeneratorLabel(generator).length,
      getGeneratorLabel(generator).length
    );
    mentionsRef.current.focus();
  }

  return (
    <Box ref={drop} flexGrow={1}>
      <Mentions
        ref={mentionsRef}
        allowClear
        autoFocus={isActive}
        prefix={"{{"}
        placeholder="new value"
        value={getGeneratorLabel(generator)}
        disabled={generator?.type != GeneratorType.FREEFORM}
        readOnly={generator?.type != GeneratorType.FREEFORM}
        onSelect={(option) => {
          const currentValue = getGeneratorLabel(generator);
          const lastOpenBrace = currentValue.lastIndexOf("{{", cursorPosition);
          const beforeCursor = currentValue.substring(0, lastOpenBrace);
          const textAfterVariable = currentValue.substring(cursorPosition);
          const newValue = `${beforeCursor}{{${option.value}}}${textAfterVariable}`;
          handleUpdate(section, fieldKey, {
            type: GeneratorType.FREEFORM,
            value: newValue,
            displayValue: newValue,
            dataType: FieldDataType.STRING,
          });
        }}
        onChange={(text: string) => {
          const input = mentionsRef.current?.textarea; // Access the textarea in Mentions
          if (input) {
            setCursorPosition(input.selectionStart); // Save the cursor position
          }
          handleUpdate(section, fieldKey, {
            type: GeneratorType.FREEFORM,
            value: text,
            displayValue: text,
            dataType: FieldDataType.STRING,
          });
        }}
        options={[
          ...(variables?.map((variable) => ({
            value: `${variable.name}`,
            label: variable.name,
          })) || []),
          ...(parameters?.map((param) => ({
            value: `${param}`, // Assuming the same format is needed
            label: param,
          })) || []),
          ...(envVariables?.map((param) => ({
            value: `${param}`, // Assuming the same format is needed
            label: param,
          })) || []),
        ]}
      />
    </Box>
  );
};

export default memo(ComboTextField);
