import {
  PaginationRequest,
  PaginationResult,
  Timestamp,
  QualifiedOperation,
  QualifiedOperationWithStatus,
} from "../../api/services/Common";
import { IAttributeValueLocator, ITestName } from "../../test-studio/models";
import { IQuery } from "../explore";

export interface IFixedWindow {
  fixed_window: {
    end_time?: string;
    start_time?: string;
  };
}

export type IMarkSessionAsPermanentRequest = {
  session_id: string;
}

export type IListSessionUsersRequest = {
  environment: string;
  time_window: IFixedWindow;
};

export type IListSessionUsersResponse = {
  userIds: [string];
};

export type IListSessionEventsRequest = {
  sessionRecordTrackingId: string;
  paginationRequest: PaginationRequest;
};

export type IListSessionEventsResponse = {
  events: [SessionEvent];
  paginationResult: PaginationResult;
  sessionRecordTimestampMillis?:number;
};

export interface IListTracesForRecordedSessionRequest {
  session_recording_tracking_id?: string;
  test_invocation_id?: string;
}

export interface IListTracesForRecordedSessionResponse {
  traces: ITraceDetail[];
}

export interface ITraceDetail {
  traceId: string;
  entryOperation: QualifiedOperation;
  endpoint: string;
  timestamp: string;
}

export enum SessionPersistenceLevel {
  UNKNOWN_SESSION_PERSISTENCE_LEVEL = "UNKNOWN_SESSION_PERSISTENCE_LEVEL",
  TEMPORARY = "TEMPORARY",
  PERMANENT = "PERMANENT"
}

export type SessionDetail = {
  sessionRecordingTrackingId: string;
  startTime: Timestamp;
  durationMins?:number;
  entryOperations: [QualifiedOperationWithStatus];
  associatedTests: [ITestName];
  sessionPersistenceLevel: SessionPersistenceLevel;
};

export interface IListSessionRecordingsRequest {
  user_id?: string;
  entry_operations?: [QualifiedOperation];
  time_window?: IFixedWindow;
  environment?: string;
  query?: IQuery;
  context?: IAttributeValueLocator;
}

export interface IListSessionRecordingsResponse {
  sessions: [SessionDetail];
}

export type SessionEvent = {
  payload: string;
};
