import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Modal, Typography } from "antd";
import Crawl from "../api/services/Crawl";
import OrganizationPreOobe from "../api/services/OrganizationPreOobe";
import { ProjectTS } from "../api";
import LocalStorageManager from "../common/LocalStorageManager";
import { getDomain } from "../util/UriUtils";
import { storeUserInfoInLocalStorage } from "../api/services/User";
import Scenarios from "../api/services/Scenarios";

interface OOBEWizardProps {
    onFlowSelect: (selectedFlow: "API_TEST" | "TEST_PLAN" | "EXPLORE") => void;
}

const OOBEWizard: React.FC<OOBEWizardProps> = ({ onFlowSelect }) => {
    const [projectName, setProjectName] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [organizationName, setOrganizationName] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [isCreatingOrg, setIsCreatingOrg] = useState(false);
    const [isCreatingProject, setIsCreatingProject] = useState(false);

    // State variables
    const [summary, setSummary] = useState<string | undefined>(undefined);
    const [isDevAgency, setIsDevAgency] = useState<boolean | undefined>(undefined);
    const [projectId, setProjectId] = useState<string | null>(null);
    const [domain, setDomain] = useState<string | null>(null);

    // Form references
    const [orgForm] = Form.useForm();
    const [projectForm] = Form.useForm();

    useEffect(() => {
        if (summary && projectId) {
            LocalStorageManager.setProjectId(projectId);
            Scenarios.updateProjectDescription({ description: summary + "" });
        }
    }, [summary, projectId]);

    useEffect(() => {
        const fetchContent = async () => {
            const url: string = domain!.startsWith("https://") ? domain! : "https://" + domain;
            const content = await Crawl.extractContent({ url });
            setSummary(content.summary);
            setIsDevAgency(content.isDevAgency);
        };

        if (domain) {
            const domainParts = domain.split(".");
            console.log("Domain parts:", domainParts);
            console.log("Org Name", organizationName);
            if (!organizationName) {
                console.log("Setting org name to", domainParts[0]);
                setOrganizationName(domainParts[0]);
                orgForm.setFieldsValue({ organizationName: domainParts[0] });
            }
            fetchContent();
        }
    }, [domain]);

    useEffect(() => {
        const userInfo = LocalStorageManager.getDetailedUserInfo();
        if (userInfo) {
            const email = userInfo.userInfo?.email || "";
            const domain = getDomain(email);
            setDomain(domain);
        }
    }, []);

    const stepTitles = ["Create Organization", "Create a Project", "Got it! What would you like to do now?"];

    // Step Handlers
    const handleCreateOrganization = async () => {
        setIsCreatingOrg(true);
        try {
            const { organizationName, referralCode } = orgForm.getFieldsValue();
            await OrganizationPreOobe.addOrganization(organizationName, referralCode);
            await storeUserInfoInLocalStorage();
            setCurrentStep(1);
        } catch (error) {
            message.error("Failed to create organization. Please try again later.");
        }
        setIsCreatingOrg(false);
    };

    const handleCreateProject = async () => {
        setIsCreatingProject(true);
        try {
            const { projectName, clientUrl } = projectForm.getFieldsValue();
            if (clientUrl) {
                const url = new URL(clientUrl);
                const extractedDomain = url.hostname.replace(/^[a-z]+:\/\//, "");
                setDomain(extractedDomain);
            }
            const projectResponse = await ProjectTS.addProject({ projectName });
            setProjectId(projectResponse.projectId);
            setCurrentStep(2);
        } catch (error) {
            message.error("Failed to create project. Please try again.");
        }
        setIsCreatingProject(false);
    };

    const handleFlowSelect = (selectedFlow: "API_TEST" | "TEST_PLAN" | "EXPLORE") => {
        if (domain && projectId) {
            onFlowSelect(selectedFlow);
        }
    };

    return (
        <Modal
            visible={true}
            footer={null} // Hides default footer
            closable={false} // Prevent closing without completion
            centered // Ensures the modal is vertically centered
            width={600} // Set a specific width for the modal
        >
            <Typography.Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>{stepTitles[currentStep]}</Typography.Title>

            {currentStep === 0 && (
                <Form form={orgForm} layout="vertical" style={{ marginTop: 24 }}>
                    <Form.Item
                        label="Organization Name"
                        name="organizationName"
                        rules={[{ required: true, message: "Please enter organization name" }]}
                    >
                        <Input
                            placeholder="Enter organization name"
                            onChange={(e) => {
                                setOrganizationName(e.target.value);
                                orgForm.setFieldsValue({ organizationName: e.target.value }); // Sync input changes with the form
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Referral Code" name="referralCode">
                        <Input
                            placeholder="Enter referral code (Optional)"
                            value={referralCode}
                            onChange={(e) => setReferralCode(e.target.value)}
                        />
                    </Form.Item>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="primary" disabled={!organizationName} className="primary-button" onClick={handleCreateOrganization} loading={isCreatingOrg}>
                            Create Organization
                        </Button>
                    </div>
                </Form>
            )}

            {currentStep === 1 && (
                <Form form={projectForm} layout="vertical" style={{ marginTop: 24 }}>
                    <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[{ required: true, message: "Please enter project name" }]}
                    >
                        <Input
                            placeholder="Enter project name"
                            value={projectName}
                            onChange={(e) => {
                                setProjectName(e.target.value);
                                orgForm.setFieldsValue({ projectName: e.target.value }); // Sync input changes with the form
                            }} />
                    </Form.Item>
                    {isDevAgency && (
                        <Form.Item
                            label="Client URL"
                            name="clientUrl"
                            rules={[{ required: true, message: "Please enter a client site URL (Optional)" }]}
                        >
                            <Input />
                        </Form.Item>
                    )}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="primary" disabled={!projectName} onClick={handleCreateProject} className="primary-button" loading={isCreatingProject}>
                            Create Project
                        </Button>
                    </div>
                </Form>
            )}

            {currentStep === 2 && (
                <div style={{ marginTop: 24, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography.Text className="guide-text">Creating API tests in seconds is what we're known for,  you might want to start here...</Typography.Text>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
                        <Button type="primary" className="primary-button" onClick={() => handleFlowSelect("API_TEST")}>
                            Create your first API test
                        </Button>
                    </div>
                    <Typography.Text className="guide-text" style={{ marginTop: 32 }}>However, we are pretty handy at whipping up test plans quickly as well...</Typography.Text>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
                        <Button type="primary" className="secondary-button" onClick={() => handleFlowSelect("TEST_PLAN")}>
                            Create a Test Plan
                        </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
                        <Button type="dashed" onClick={() => handleFlowSelect("EXPLORE")} size="small">
                            Explore on my own
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default OOBEWizard;