import React, { useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { CaretDownOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import { Dropdown, Mentions, MenuProps } from "antd";
import GeneratorTextField from "./GeneratorTextField";
import {
  CONTENT_TYPE,
  FieldDataType,
  GeneratorType,
  HTTPMessageSection,
  IDrawerContext,
  IGenerator,
  IGeneratorKV,
  IVariable,
} from "../models";
import { isEmpty } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DraftGeneratorTextField from "./DraftGeneratorTextField";

interface IKVTable {
  menu?: {
    props: MenuProps;
    handleOpen: (currentMenuContext: { open: boolean; context: IDrawerContext }) => void;
  };
  enabled: boolean;
  messageKV: { [key: string]: string };
  generatorKV: IGeneratorKV;
  removeGenerator?: (section: HTTPMessageSection, key: string) => void;
  setGenerator?: (key: string, section: HTTPMessageSection, generator: IGenerator) => void;
  variables: IVariable[];
  parameters: String[];
  envVariables: String[];
  section: HTTPMessageSection;
}

const KVTable: React.FC<IKVTable> = ({
  menu,
  enabled,
  messageKV,
  generatorKV,
  removeGenerator,
  setGenerator,
  variables,
  parameters,
  envVariables,
  section,
}) => {
  const initialGenerator = {
    type: GeneratorType.FREEFORM,
    value: "",
    displayValue: "",
    dataType: FieldDataType.STRING,
  };

  const keys = Object.keys(isEmpty(generatorKV) ? (isEmpty(messageKV) ? [] : messageKV) : generatorKV);

  const [newKey, setNewKey] = useState("");
  const [newGenerator, setNewGenerator] = useState<IGenerator>(initialGenerator);

  const addNewKV = () => {
    setGenerator!(newKey, section, newGenerator);
    setNewKey("");
    setNewGenerator(initialGenerator);
  };

  return (
    <Box>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {keys?.map((key) => {
              return (
                <TableRow
                  key={key}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell width="170em">
                    <Typography>{key}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
                      {!isEmpty(menu) &&
                        (key != CONTENT_TYPE ? (
                          <Dropdown
                            menu={menu.props}
                            onOpenChange={(open) =>
                              menu.handleOpen!({
                                open,
                                context: {
                                  section: section,
                                  key: key,
                                  value: generatorKV[key]?.value ?? messageKV[key],
                                  headerKey: key,
                                  modelKey: key,
                                },
                              })
                            }
                          >
                            <IconButton>
                              <CaretDownOutlined style={{ fontSize: "20px" }} />
                            </IconButton>
                          </Dropdown>
                        ) : (
                          <Tooltip
                            title={
                              "You may update manually for custom content types. But changing body type will override it to the matching standard content type."
                            }
                          >
                            <IconButton disableRipple>
                              <InfoIcon style={{ fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                        ))}
                      {enabled ? (
                        generatorKV && (
                          <>
                            <GeneratorTextField
                              fieldKey={key}
                              section={section}
                              generator={generatorKV[key]}
                              variables={variables}
                              parameters={parameters}
                              envVariables={envVariables}
                            />
                            <IconButton size="small" onClick={() => removeGenerator!(section, key)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </>
                        )
                      ) : (
                        <Box flexGrow={1}>
                          <Mentions disabled readOnly value={generatorKV?.[key]?.value ?? messageKV?.[key] ?? ""} />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
            {enabled && (
              <TableRow
                key="new"
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell width="170em">
                  <Box flexGrow={1}>
                    <Mentions placeholder="new key" value={newKey} onChange={(text) => setNewKey(text)} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
                    <DraftGeneratorTextField
                      fieldKey={newKey}
                      section={section}
                      generator={newGenerator}
                      setGenerator={setNewGenerator}
                      variables={variables}
                      parameters={parameters}
                      envVariables={envVariables}
                    />
                    <IconButton size="small" disabled={!newKey} onClick={addNewKV}>
                      <CheckCircleIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KVTable;
