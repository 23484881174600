import { Box, Stack } from "@mui/material";
import { Typography } from "antd";
import React, { FC } from "react";

const { Text, Title } = Typography;

const InfoMessage: FC<{ title: string, description: string }> = ({ title, description }) => {
  return (
    <Stack direction={"column"} alignItems={"center"} paddingTop="10em" paddingLeft="5em" paddingRight="5em" spacing={8}>
      <Title level={5}>{title}</Title>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </Stack>
  );
};

export default InfoMessage;

