import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, Typography, Checkbox, message, Row, Col } from 'antd';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ProjectTS } from '../api';
import { setProjectConfig } from '../admin/ProjectSettings/projectSettingsSlice';
import StartCaptureImg from "../images/StartCaptureIllustrate.gif";
import EndCaptureImg from "../images/EndCaptureIllustrate.gif";
import SessionLinkImg from "../images/SessionLinkIllustrate.gif";
import CreateTestImg from "../images/CreateTestIllustrate.gif";
import SaveTestImg from "../images/SaveTestIllustrate.gif";
import GenScriptImg from "../images/GenScriptIllustrate.gif";
import LocalStorageManager from '../common/LocalStorageManager';
import { getDomain } from '../util/UriUtils';

interface QuickStartWizardProps {
    onComplete: () => void;
}

interface Step {
    title: string;
    description: React.ReactNode;
    action?: React.ReactNode;
    nextStepName: string;

}

const QuickStartWizard: React.FC<{ onComplete: (showCreateTestPlanGuide: boolean) => void }> = ({ onComplete }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<boolean[]>(Array(8).fill(false));
    const [urlRegex, setUrlRegex] = useState('');
    const [includeCognito, setIncludeCognito] = useState(false);
    const [includeFirebase, setIncludeFirebase] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState('Configure Extension');
    const [domain, setDomain] = useState<string>("");
    const [userMail, setUserMail] = useState<string>("");

    const selectedProject = useAppSelector((state) => state.common.selectedProject);
    const projectSettings = useAppSelector((state) => state.projectSettings);
    const sessionRecordApiKey = projectSettings.projectConfig.sessionRecordApiKey;
    const dispatch = useAppDispatch();
    const toggleOAuthEndpoint = (endpoint: string) => {
        let updatedRegex = urlRegex.split(",").map(str => str.trim());

        if (endpoint === "cognito") {
            if (includeCognito) {
                // Remove Cognito URL regex if it already exists
                updatedRegex = updatedRegex.filter(url => url !== ".*amazonaws.*");
            } else {
                // Add Cognito URL regex if it doesn't exist
                if (!updatedRegex.includes(".*amazonaws.*")) {
                    updatedRegex.push(".*amazonaws.*");
                }
            }
            setIncludeCognito(!includeCognito);
        } else if (endpoint === "firebase") {
            if (includeFirebase) {
                // Remove Firebase URL regex if it already exists
                updatedRegex = updatedRegex.filter(url => url !== ".*identitytoolkit.googleapis.*");
            } else {
                // Add Firebase URL regex if it doesn't exist
                if (!updatedRegex.includes(".*identitytoolkit.googleapis.*")) {
                    updatedRegex.push(".*identitytoolkit.googleapis.*");
                }
            }
            setIncludeFirebase(!includeFirebase);
        }

        setUrlRegex(updatedRegex.join(","));
    };

    const openExtensionOptions = () => {
        console.log("Sending message open options");
        window.postMessage({ type: "tc_open_options_page" }, "*");
    };

    useEffect(() => {
        if (selectedProject) {
            ProjectTS.getConfig({ projectId: selectedProject }).then(response => {
                dispatch(setProjectConfig(response.projectConfig));
            });
            const userInfo = LocalStorageManager.getDetailedUserInfo();
            if (userInfo) {
                const email = userInfo.userInfo?.email || '';
                const domain = getDomain(email); setDomain(domain);

                setUrlRegex(`.*${domain}.*`);
                setUserMail(email);

            }
        }
    }, [selectedProject, dispatch]);


    const configureExtension = () => {
        setIsLoading(true);
        setButtonText("Configuring...");

        const payload = {
            projectId: selectedProject,
            sessionRecordingApiKey: sessionRecordApiKey,
            uriRegexToIntercept: urlRegex,
            currentUserId: userMail
        };

        console.log("Sending message to ext");
        window.postMessage({ type: "update_tc_ext_config", payload: payload }, "*");

        // Set a timeout for 1 second in case of no response
        const timeout = setTimeout(() => {
            setIsLoading(false);
            setButtonText("Could not configure extension.");
        }, 5000);

        // Listener for response from the extension
        const handleExtensionResponse = (event: MessageEvent) => {
            if (event.data.type === "update_tc_ext_config_response") {
                clearTimeout(timeout);  // Clear the timeout if a response is received
                console.log("Event recv", event);
                if (event.data.success) {
                    setIsLoading(false);
                    message.success("Extension configured successfully!");
                    setButtonText("Configured successfully!");
                    setTimeout(() => {
                        handleDoneClick(1);
                        setButtonText("Configure Extension");
                    }, 1000); // Delay to show success text before moving to next step
                } else {
                    setButtonText("Could not configure extension.");
                }

                // Clean up the event listener
                window.removeEventListener("message", handleExtensionResponse);
            }
        };

        window.addEventListener("message", handleExtensionResponse);
    };

    const steps: Step[] = [
        {
            title: 'Install Chrome Extension',
            description: (
                <div>
                    <Typography.Text className='guide-text'>
                        Why do we ask you to install an extension?
                    </Typography.Text>
                    <Typography.Text className='guide-text'>
                        We use it to observe manual test sessions. Then our Chimps get to work generating API tests covering the full user journey.
                    </Typography.Text>
                    <a href="https://chromewebstore.google.com/detail/testchimp-create-api-auto/ailhophdeloancmhdklbbkobcbbnbglm"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="primary" className="secondary-button">
                                Install Extension
                            </Button>
                        </div>
                        <Typography.Text className='guide-text' style={{ marginTop: '20px' }}>
                            <i>While most people start with the extension, our SDKs enable more seamless always-on auto-capture of your test sessions. Read more <a href="https://www.testchimp.io/blog/getting-started-with-testchimp" target='_blank'>here</a>.</i>
                        </Typography.Text>
                    </a>
                </div>
            ),
            nextStepName: "Configure Extension"
        },
        {
            title: 'Configure Extension',
            description: (
                <>
                    <Typography.Text className='guide-text'>
                        Got that extension installed?  Great, now we can help you get it set up.
                    </Typography.Text>
                    <Typography style={{ marginTop: 20 }}>Enter backend API URL (regex) to capture. <i>(comma separate multiple values)</i>:</Typography>
                    <Input
                        value={urlRegex}
                        onChange={(e) => setUrlRegex(e.target.value)}
                        style={{ marginTop: 10, marginBottom: 10 }}
                        suffix={<CopyOutlined onClick={() => navigator.clipboard.writeText(urlRegex)} />}
                    />

                    <Checkbox
                        checked={includeCognito}
                        onChange={() => toggleOAuthEndpoint("cognito")}
                        style={{ marginTop: 10 }}
                    >
                        Add Cognito OAuth endpoint
                    </Checkbox>
                    <Checkbox
                        checked={includeFirebase}
                        onChange={() => toggleOAuthEndpoint("firebase")}
                        style={{ marginTop: 10 }}
                    >
                        Add Firebase OAuth endpoint
                    </Checkbox>
                    <Typography.Text className='guide-text'>
                        Now just hit that <b>Configure Extension</b> button below and everything should just work.
                    </Typography.Text>
                    <Typography.Text className='guide-text'><i>You can change later in <a href="#" onClick={openExtensionOptions}>extension -&gt; Options</a>.</i></Typography.Text>
                </>
            ),
            action: (
                <Button
                    type="primary"
                    className="primary-button"
                    loading={isLoading}
                    onClick={configureExtension}
                    style={{ marginTop: 16 }}
                >
                    {buttonText}
                </Button>
            ),
            nextStepName: "Start Capture"
        },
        {
            title: 'Start Capture',
            description: (
                <>
                    <Typography.Text className='guide-text'>
                        Everything's set up, now we just need you to run through a user journey.  Like the eye of sauron we'll observe what you're doing and build tests from the data we capture.
                    </Typography.Text>
                    <Typography.Text className='guide-text'>
                        BUT before you do anything, hit that <b>Start Capture</b> button!
                    </Typography.Text>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={StartCaptureImg} width="300" height="auto" alt="Start Capture" />
                    </div>
                    <Typography.Text className='guide-text'>
                        <i>Note: If your site was open before installing the extension, remember reload the website.</i>
                    </Typography.Text>
                </>
            ),
            nextStepName: "End Capture"
        },
        {
            title: 'End Capture',
            description: (
                <>
                    <Typography.Text className='guide-text'>
                        All good things must come to an end ... in this case you need to end it.
                    </Typography.Text>
                    <Typography.Text className='guide-text'>
                        Once you're done with testing a user journey, click <b>End Capture</b>.
                    </Typography.Text>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={EndCaptureImg} width="300" height="auto" alt="End Capture" />
                    </div>
                </>
            ),
            nextStepName: "View session recording"
        },
        {
            title: 'Go to Session Capture',
            description: (
                <>
                    <Typography.Text className='guide-text'>
                        Once you've recorded a session (or two) you'll find them in the extension, or you can see all your sessions later in the <b>Sessions tab</b>.
                    </Typography.Text>
                    <Typography.Text className='guide-text'>
                        You'll see a recording of your session and a list of the steps we've observed.
                    </Typography.Text>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={SessionLinkImg} width="300" height="auto" alt="Session Capture Link" />
                    </div>
                </>
            ),
            nextStepName: "Create Test"
        },
        {
            title: 'Create Test',
            description: (
                <>
                    <Typography>
                        <Typography.Text className='guide-text'>
                            Happy you're recording has correctly captured your test scenario?
                        </Typography.Text >
                        <Typography.Paragraph className='guide-text'>
                            Click the <strong>Create Test</strong> button in the top right.
                        </Typography.Paragraph>
                        <Typography.Paragraph className='guide-text'>
                            TestChimp will analyze the payloads and automatically suggest assertions to add and variables to define.
                        </Typography.Paragraph>
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={CreateTestImg} width="300" height="auto" alt="create Test" />
                    </div>
                </>
            ),
            nextStepName: "Save Test"
        },
        {
            title: 'Save Test',
            description: (
                <>
                    <Typography.Text className='guide-text'>
                        Hit <strong>Save</strong> in the bottom left.
                    </Typography.Text>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <img src={SaveTestImg} width="300" height="auto" alt="Save Test" />
                    </div>
                </>
            ),
            nextStepName: "Generate Script"
        },
        {
            title: 'Generate Script',
            description: (
                <>
                    <Typography.Text className='guide-text'>Your test is now ready to be run!</Typography.Text>
                    <Typography.Text className='guide-text'>Alternatively, you can export your test as a script in <i>ANY</i> preferred language <i>(Playwright, Cypress, Mocha etc.)</i>.</Typography.Text>
                    <Typography.Text className='guide-text'>Click More -&gt;<b> Generate Script</b> and select your preferred framework to get a beautifully written script for the test.</Typography.Text>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                        <img src={GenScriptImg} width="300" height="auto" alt="Generate Script" />
                    </div>
                </>
            ),
            nextStepName: ""
        },
    ];

    const nextStep = () => {
        setCompletedSteps((prev) => {
            const updated = [...prev];
            updated[currentStep] = true;
            return updated;
        });
        setCurrentStep(currentStep + 1);
    };

    const handleDoneClick = (index: number) => {
        const updatedCompletedSteps = [...completedSteps];
        updatedCompletedSteps[index] = true;
        setCompletedSteps(updatedCompletedSteps);

        // Move to next step if there is one
        const next = index + 1;
        if (next < steps.length) {
            nextStep();
        } else {
            onComplete(false);
        }
    };


    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <>

            <Modal
                title={`${steps[currentStep].title}: (Step ${currentStep + 1} of ${steps.length}) `}
                open={true}
                onCancel={() => onComplete(false)}
                footer={null}
                width={800}
            >
                <div style={{ marginTop: 24 }}>{steps[currentStep].description}</div>
                {steps[currentStep].action && <div style={{ marginTop: 16 }}>{steps[currentStep].action}</div>}
                <div style={{ marginTop: 32, textAlign: 'right' }}>
                    {currentStep > 0 && (
                        <Button onClick={prevStep} className="secondary-button"
                            style={{ marginRight: 8 }}>
                            Previous
                        </Button>
                    )}
                    {currentStep < steps.length - 1 ? (
                        <Button type="primary" className="primary-button"
                            onClick={nextStep}>
                            Next: {steps[currentStep].nextStepName}
                        </Button>
                    ) : (
                        <Button type="primary" className="primary-button"
                            onClick={() => onComplete(false)}>
                            Finish
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default QuickStartWizard;