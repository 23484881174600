import { OasConfig, Project, ProjectConfig } from "../../models/config/models";
import { ResponseHeader } from "../Response";
import client from "../client";
import { QualifiedOperation } from "./Common";
import { ListResourcesRequest } from "./User";

interface IAttribConfigSemanticId {
  name: string;
  projectId: string;
  operation: QualifiedOperation;
}

interface IAttribConfig {
  tags: string[];
  attribHashConfig: {
    hashMethod: string;
  };
  valueRetentionConfig: {
    maxCardinality: number;
  };
}

export interface IUpdateAttributeConfigRequest {
  attribConfigSemanticId: IAttribConfigSemanticId;
  attribConfig: IAttribConfig;
}

export interface IGetAttributeConfigRequest {
  attribConfigSemanticId: IAttribConfigSemanticId;
}

export interface IGetAttributeConfigResponse {
  attribName: string;
  projectId: string;
  operation: QualifiedOperation;
  attribConfig: IAttribConfig;
}

export interface IGetProjectConfig {
  projectId: string;
}

export interface IGetProjectConfigResponse {
  projectConfig: ProjectConfig;
}

export interface IUpdateProjectConfigRequest {
  projectId: string;
  projectConfig: ProjectConfig;
}

export enum ProjectAssetType {
  UNKNOWN_PROJECT_ASSET_TYPE = "UNKNOWN_PROJECT_ASSET_TYPE",
  API_TEST_UTIL_FILE = "API_TEST_UTIL_FILE",
  EXAMPLE_API_TEST_FILE = "EXAMPLE_API_TEST_FILE",
}

export enum ProjectAssetStatus {
  UNKNOWN_PROJECT_ASSET_STATUS = "UNKNOWN_PROJECT_ASSET_STATUS",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIED"
}

export interface ProjectAsset {
  assetId?: number;
  name: string;
  relativePath: string;
  content: string;
  type: ProjectAssetType;
  status: ProjectAssetStatus;
}

export interface ListProjectAssetsRequest {

}
export interface ListProjectAssetsResponse {
  assets: ProjectAsset[];
}

export interface UpsertProjectAssetsRequest {
  assets: ProjectAsset[];
}

export interface UpsertProjectAssetsResponse {
  ids: number[];
}

export interface DeleteProjectAssetRequest {
  assetId: number;
}

export interface GetAssetContentRequest {
  id: number;
}

export interface GetAssetContentResponse {
  content?: string;
}

export interface DeleteProjectAssetResponse {

}

export interface AddProjectResponse {
  responseHeader?: ResponseHeader;
  projectId: string;
}

export interface AddProjectRequest {
  projectName: string;
}

export interface IListEntryPointRequest {
  resourceId: number;
}

export interface IUpdateResourceConfigsRequest {
  configs: ResourceConfig[];
}

export interface IUpdateResourceConfigsResponse {

}

export interface IGetUserProjectsResponse {
  responseHeader?: ResponseHeader;
  projects: Project[];
}

export interface ResourceConfig {
  resourceName: string;
  oasConfig?: OasConfig;
}

const ProjectTS = {
  updateAttributeConfig: async (request: IUpdateAttributeConfigRequest) => {
    const response = await client.post("org/update_attrib_config", request);
    return response.data;
  },
  getAttributeConfig: async (request: IGetAttributeConfigRequest) => {
    const response = await client.post("org/get_attrib_config", request);
    return response.data;
  },
  updateResourceConfigs: async (request: IUpdateResourceConfigsRequest) => {
    const response = await client.post("org/update_resource_configs", request);
    return response.data;
  },
  getConfig: async (request: IGetProjectConfig): Promise<IGetProjectConfigResponse> => {
    const response = await client.post("/admin/get_project_config", request);
    return response.data;
  },
  updateProjectConfig: async (request: IUpdateProjectConfigRequest) => {
    const response = await client.post("/admin/update_project_config", request);
    return response.data;
  },
  listResources: async (request: ListResourcesRequest) => {
    const response = await client.post("collectors/list_resources", request);
    return response.data;
  },
  listProjectAssets: async (request: ListProjectAssetsRequest) => {
    const response = await client.post("org/list_project_assets", request);
    return response.data;
  },
  upsertProjectAssets: async (request: UpsertProjectAssetsRequest) => {
    const response = await client.post("org/upsert_project_assets", request);
    return response.data;
  },
  deleteProjectAsset: async (request: DeleteProjectAssetRequest) => {
    const response = await client.post("org/delete_project_asset", request);
    return response.data;
  },
  getAssetContent: async (request: GetAssetContentRequest) => {
    const response = await client.post("org/get_asset_content", request);
    return response.data;
  },
  listEntrypoints: async (request: IListEntryPointRequest) => {
    const response = await client.post("collectors/list_entrypoints", request);
    return response.data;
  },
  listEnvironments: async () => {
    const response = await client.post("collectors/list_environments", {});
    return response.data;
  },
  addProject: async (request: AddProjectRequest): Promise<AddProjectResponse> => {
    const response = await client.post("admin/add_project", request);
    return response.data;
  },
  listProjects: async (): Promise<IGetUserProjectsResponse> => {
    const response = await client.post("user/get_projects", {});
    return response.data;
  },
};

export default ProjectTS;
