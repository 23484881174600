import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Tabs, Tab, Box, Container, Paper } from '@mui/material';
import { saveProjectConfig, saveResourceConfigs, setProjectConfig, updateConfigAndSave } from './projectSettingsSlice';
import GeneralSettings from './GeneralSettings';
import EnvironmentSettings from './EnvironmentSettings';
import ProjectAssetManager from './ProjectAssetsManager';
import PathTemplates from './PathTemplates';
import ProjectTS from '../../api/services/ProjectTS';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import theme from "../../common/theme";
import { ThemeProvider } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { Button, message } from 'antd';

const ProjectSettings: React.FC<{ projectId: string }> = ({ projectId }) => {
  const dispatch = useAppDispatch();
  const projectConfig = useAppSelector(state => state.projectSettings.projectConfig);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
  let tab = searchParams.get("tab");

  useEffect(() => {
    if (tab && projectConfig?.apiKey) {
      console.log("ProjectConfig", projectConfig);
      if (tab == "environment-settings") {
        setSelectedTab(1);
        tab = "";
      }
    }
  }, [projectConfig]);

  useEffect(() => {
    if (projectId) {
      ProjectTS.getConfig({ projectId }).then(response => {
        dispatch(setProjectConfig(response.projectConfig));
      });
    }
  }, [projectId, dispatch]);

  const handleSave = async () => {
    setIsSavingInProgress(true);
    const response = await dispatch(updateConfigAndSave()); // This will update the projectConfig state with the latest environment variable changes
    await dispatch(saveProjectConfig());
    await dispatch(saveResourceConfigs());
    message.success("Project settings saved successfully!");
    setIsSavingInProgress(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '30em', width: "75em" }}>
        <Container sx={{ display: 'flex', flexDirection: 'row', padding: 0, flexGrow: 1 }}>
          <AppBar position="static" sx={{ width: '250px', backgroundColor: "var(--light-color)" }}>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              orientation="vertical"
            >
              <Tab label="General Settings" />
              <Tab label="Environment Settings" />
              <Tab label="Path Templates" />
              <Tab label="Project Assets" />
            </Tabs>
          </AppBar>
          <Paper sx={{ flexGrow: 1, width: 'calc(100% - 250px)', marginLeft: 2, padding: 2 }}>
            <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
              {selectedTab === 0 && <GeneralSettings />}
              {selectedTab === 1 && <EnvironmentSettings />}
              {selectedTab === 2 && <PathTemplates />}
              {selectedTab === 3 && <ProjectAssetManager />}
            </Box>
          </Paper>
        </Container>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
          <Button loading={isSavingInProgress} onClick={handleSave} type="primary" className="primary-button">
            Save
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ProjectSettings;