import { Box, IconButton, MenuItem, TextField, Button, Typography } from "@mui/material";
import { isObject, isArray, isEmpty, cloneDeep, debounce } from "lodash";
import { Dropdown, Mentions, Space, Tabs, TabsProps, Tree, Typography as TY } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { grey } from "@mui/material/colors";
import GeneratorTextField from "./GeneratorTextField";

import {
  CONTENT_TYPE,
  ContentType,
  ContentTypeMetadata,
  FieldDataType,
  GeneratorType,
  HTTPBodyMode,
  HTTPMessageProps,
  HTTPMessageSection,
  IHTTPBodyTreeNode,
  IMenu,
  NO_BODY_METHODS,
} from "../models";
import { useEffect, useRef, useState } from "react";
import "./TabStyle.css";
import KVTable from "./KVTable";
import { processPlainBody } from "../../util/MessageUtils";
import RawEditor, { RawEditorHandle } from "./RawEditor";
import ComponentLoader from "../../common/ComponentLoader";
import InfoMessage from "./InfoMessage";

const { Text } = TY;

const HTTPMessage: React.FC<HTTPMessageProps> = ({
  message,
  menu,
  loading,
  method,
  body,
  tree,
  generators,
  availableVariables,
  availableParameters,
  availableEnvVariables,
}) => {
  const [sectionKey, setSectionKey] = useState<string>("body");
  const [bodyMode, setBodyMode] = useState<HTTPBodyMode>(HTTPBodyMode.PLAIN);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [assertionsOnlyMenu, setAssertionsOnlyMenu] = useState<IMenu | undefined>(undefined);
  const rawEditorRef = useRef<RawEditorHandle>(null);

  useEffect(() => {
    if (menu && !assertionsOnlyMenu) {
      let assertionsMenu = cloneDeep(menu);
      assertionsMenu.props.items = [
        {
          key: "SET_AS_LEFT_EXPRESSION",
          label: "Set as a left expression",
        },
        {
          key: "SET_AS_RIGHT_EXPRESSION",
          label: "Set as a right expression",
        },
      ];
      setAssertionsOnlyMenu(assertionsMenu);
    }
  }, [menu]);

  useEffect(() => {
    switch (body.type) {
      case ContentType.FORM_URL_ENCODED:
        setBodyMode(HTTPBodyMode.KV);
        break;
      case ContentType.MULTIPART_FORM:
        setBodyMode(HTTPBodyMode.KV);
        break;
      case ContentType.JSON:
        isEmpty(tree.body) ? setBodyMode(HTTPBodyMode.PLAIN) : setBodyMode(HTTPBodyMode.FORM);
        break;
      case ContentType.TEXT:
      case ContentType.XML:
      case ContentType.HTML:
        setBodyMode(HTTPBodyMode.PLAIN);
        break;
      default:
        setBodyMode(HTTPBodyMode.PLAIN);
        break;
    }
  }, [body.type]); // removed tree.body from the dependency array

  useEffect(() => {
    if (NO_BODY_METHODS.includes(method)) {
      handleBodyTypeChange(ContentType.NO_CONTENT);
    }
  }, [method]);

  const handleBodyModeChange = () => {
    if (bodyMode === HTTPBodyMode.PLAIN) {
      setBodyMode(HTTPBodyMode.FORM);
    } else {
      setBodyMode(HTTPBodyMode.PLAIN);
    }
  };

  const debouncedUpdateBodyGeneratorsAndTree = debounce(async (bodyString: string) => {
    //can make processPlainBody async
    if (!hasErrors) {
      const { bodyTree, bodyGenerators } = processPlainBody(bodyString, generators.body);
      generators.setAll?.(HTTPMessageSection.BODY, bodyGenerators);
      tree.setBody(bodyTree);
    }
  }, 300);

  const updateBodyStates = (bodyString: string) => {
    body.setString(bodyString);
    debouncedUpdateBodyGeneratorsAndTree(bodyString);
  };

  const handleBodyTypeChange = (contentType: ContentType) => {
    //update body type
    body.setType(contentType);

    //update header generator
    contentType == ContentType.NO_CONTENT
      ? generators.remove?.(HTTPMessageSection.HEADER, CONTENT_TYPE)
      : generators.set?.(CONTENT_TYPE, HTTPMessageSection.HEADER, {
          type: GeneratorType.FREEFORM,
          value: ContentTypeMetadata[contentType].value,
          displayValue: ContentTypeMetadata[contentType].value,
          dataType: FieldDataType.STRING,
        });

    //update body generator
    switch (contentType) {
      case ContentType.JSON:
        const { bodyTree, bodyGenerators } = processPlainBody(body.string, generators.body);
        generators.setAll?.(HTTPMessageSection.BODY, bodyGenerators);
        tree.setBody(bodyTree);
        break;
      case ContentType.TEXT:
      case ContentType.HTML:
      case ContentType.XML:
        generators.setAll?.(HTTPMessageSection.BODY, {
          $: {
            type: GeneratorType.FREEFORM,
            value: body.string,
            displayValue: body.string,
            dataType: FieldDataType.STRING,
          },
        });
        break;
      case ContentType.MULTIPART_FORM:
      case ContentType.FORM_URL_ENCODED:
        generators.setAll?.(HTTPMessageSection.BODY, {});
        break;
      case ContentType.NO_CONTENT:
        generators.setAll?.(HTTPMessageSection.BODY, {});
        tree.setBody([]);
        break;
    }
  };

  const rawEditorSaveHandler = () => {
    if (generators.enabled && !body.isReadOnly) {
      if (body.type == ContentType.JSON) {
        const { bodyTree, bodyGenerators } = processPlainBody(body.string, generators.body);
        generators.setAll?.(HTTPMessageSection.BODY, bodyGenerators);
        tree.setBody(bodyTree);
      } else {
        generators.setAll!(HTTPMessageSection.BODY, {
          $: {
            type: GeneratorType.FREEFORM,
            value: body.string,
            displayValue: body.string,
            dataType: FieldDataType.STRING,
          },
        });
        tree.setBody([]);
      }
    }
  };

  const rawEditorOpenChangeHander = (open: boolean) => {
    menu?.handleOpen({
      open,
      context: {
        section: HTTPMessageSection.BODY,
        key: "$",
        value: body.string,
        bodyPath: "$",
        modelKey: "$",
      },
    });
  };

  const getTitle = (node: IHTTPBodyTreeNode) => {
    const { key, modelPath, attributeKey, attributeValue } = node;
    if (isArray(attributeValue)) {
      return (
        <Space>
          <Text>{attributeKey}</Text>
          {assertionsOnlyMenu && (
            <Dropdown
              menu={assertionsOnlyMenu.props}
              onOpenChange={(open) =>
                assertionsOnlyMenu.handleOpen({
                  open,
                  context: {
                    section: HTTPMessageSection.BODY,
                    key: key,
                    value: attributeValue,
                    bodyPath: key,
                    modelKey: modelPath,
                  },
                })
              }
            >
              <a onClick={(e) => e.preventDefault()} style={{ display: "flex" }}>
                <CaretDownOutlined style={{ fontSize: "20px", color: grey[600] }} />
              </a>
            </Dropdown>
          )}
        </Space>
      );
    } else if (isObject(attributeValue)) {
      return (
        <Space>
          <Text>{attributeKey}</Text>
          {assertionsOnlyMenu && (
            <Dropdown
              menu={assertionsOnlyMenu.props}
              onOpenChange={(open) =>
                assertionsOnlyMenu.handleOpen({
                  open,
                  context: {
                    section: HTTPMessageSection.BODY,
                    key: key,
                    value: attributeValue,
                    bodyPath: key,
                    modelKey: modelPath,
                  },
                })
              }
            >
              <a onClick={(e) => e.preventDefault()} style={{ display: "flex" }}>
                <CaretDownOutlined style={{ fontSize: "20px", color: grey[600] }} />
              </a>
            </Dropdown>
          )}
        </Space>
      );
    } else {
      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          borderLeft={4}
          borderTop={1}
          borderBottom={1}
          borderColor={grey[300]}
          paddingLeft={1}
          height={31}
          width={"95%"}
          flexGrow={1}
        >
          <Box width={"13em"} sx={{ wordBreak: "break-all" }}>
            <Typography fontSize={15}>{attributeKey}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1} flexGrow={1}>
            {!isEmpty(menu) && (
              <Dropdown
                menu={menu.props}
                onOpenChange={(open) =>
                  menu.handleOpen({
                    open,
                    context: {
                      section: HTTPMessageSection.BODY,
                      key: key,
                      value: attributeValue,
                      bodyPath: key,
                      modelKey: modelPath,
                    },
                  })
                }
              >
                <IconButton>
                  <CaretDownOutlined style={{ fontSize: "20px" }} />
                </IconButton>
              </Dropdown>
            )}
            {generators.enabled ? (
              generators.body && (
                <GeneratorTextField
                  fieldKey={key}
                  section={HTTPMessageSection.BODY}
                  generator={generators.body[key]}
                  variables={availableVariables}
                  parameters={availableParameters}
                  envVariables={availableEnvVariables}
                />
              )
            ) : (
              <Box flexGrow={1}>
                <Mentions disabled readOnly value={attributeValue} />
              </Box>
            )}
          </Box>
        </Box>
      );
    }
  };

  const renderBodyModeEditor = (bodyMode: HTTPBodyMode) => {
    switch (bodyMode) {
      case HTTPBodyMode.PLAIN:
        return (
          <RawEditor
            ref={rawEditorRef}
            bodyString={body.string}
            updateBodyStates={updateBodyStates}
            mode={ContentTypeMetadata[body.type]?.editorMode!}
            isRequestGenerator={generators.enabled}
            isReadOnly={body.isReadOnly}
            generators={generators.body}
            setHasErrors={setHasErrors}
            onBlur={rawEditorSaveHandler}
          />
        );
      case HTTPBodyMode.KV:
        return (
          <KVTable
            menu={menu}
            enabled={generators.enabled}
            messageKV={
              message?.body.httpFormDataBody?.keyValueMap ?? message?.body.httpFormUrlencodedBody?.keyValueMap ?? {}
            }
            generatorKV={generators.body ?? {}}
            removeGenerator={generators.remove}
            setGenerator={generators.set}
            variables={availableVariables}
            parameters={availableParameters}
            envVariables={availableEnvVariables}
            section={HTTPMessageSection.BODY}
          />
        );
      case HTTPBodyMode.FORM:
        return (
          <Tree
            treeData={tree.body}
            titleRender={(node) => getTitle(node)}
            selectable={false}
            showLine
            defaultExpandAll={tree.expandAll}
            defaultExpandParent={tree.expandParent}
            blockNode
          />
        );
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "headers",
      label: "Headers",
      children: (
        <KVTable
          menu={menu}
          enabled={generators.enabled}
          messageKV={message ? message.headers : {}}
          generatorKV={generators.header ?? {}}
          removeGenerator={generators.remove}
          setGenerator={generators.set}
          variables={availableVariables}
          parameters={availableParameters}
          envVariables={availableEnvVariables}
          section={HTTPMessageSection.HEADER}
        />
      ),
    },
    {
      key: "queryparams",
      label: "Query Params",
      children: (
        <KVTable
          menu={menu}
          enabled={generators.enabled}
          messageKV={message && "queryParams" in message ? message.queryParams : {}}
          generatorKV={generators.query ?? {}}
          removeGenerator={generators.remove}
          setGenerator={generators.set}
          variables={availableVariables}
          parameters={availableParameters}
          envVariables={availableEnvVariables}
          section={HTTPMessageSection.QUERY_PARAMS}
        />
      ),
    },
    {
      key: "pathparams",
      label: "Path Params",
      children: (
        <KVTable
          menu={menu}
          enabled={generators.enabled}
          messageKV={message && "templatePathParams" in message ? message.templatePathParams?.keyValueMap ?? {} : {}}
          generatorKV={generators.path ?? {}}
          removeGenerator={generators.remove}
          setGenerator={generators.set}
          variables={availableVariables}
          parameters={availableParameters}
          envVariables={availableEnvVariables}
          section={HTTPMessageSection.PATH_PARAMS}
        />
      ),
    },
    {
      key: "body",
      label: "Body",
      children: (
        <>
          {!message || loading ? (
            <ComponentLoader />
          ) : (
            <Box display={"flex"} flexDirection={"column"} height={"100%"} flexGrow={1}>
              {!NO_BODY_METHODS.includes(method) && (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  sx={{ padding: "8px", marginBottom: "10px", borderBottom: "1px solid #d9d9d9" }}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <TextField
                      sx={{ width: "16.5em" }}
                      size="small"
                      variant="outlined"
                      label="Body type"
                      disabled={!generators.enabled}
                      select={generators.enabled}
                      value={body.type ?? ContentType.NO_CONTENT}
                      onChange={(e) => handleBodyTypeChange(e.target.value as ContentType)}
                    >
                      {Object.keys(ContentTypeMetadata).map((contentType) => (
                        <MenuItem key={contentType} value={contentType}>
                          {contentType}
                        </MenuItem>
                      ))}
                    </TextField>

                    {message && "responseCode" in message && (
                      <>
                        <TextField
                          sx={{ width: "7em", marginLeft: 1 }}
                          size="small"
                          variant="outlined"
                          label="Status code"
                          disabled
                          value={message.responseCode}
                        />
                        {assertionsOnlyMenu && (
                          <Dropdown
                            menu={assertionsOnlyMenu.props}
                            onOpenChange={(open) =>
                              assertionsOnlyMenu.handleOpen({
                                open,
                                context: {
                                  section: HTTPMessageSection.RESPONSE_CODE,
                                  key: "code",
                                  value: message.responseCode,
                                  bodyPath: "code",
                                  modelKey: "code",
                                },
                              })
                            }
                          >
                            <IconButton>
                              <CaretDownOutlined style={{ fontSize: "20px" }} />
                            </IconButton>
                          </Dropdown>
                        )}
                      </>
                    )}
                  </Box>
                  {body.type != ContentType.NO_CONTENT && (
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
                      {body.type && bodyMode == HTTPBodyMode.PLAIN && (
                        <Button
                          variant="text"
                          disabled={hasErrors || body.string == ""}
                          onClick={() => rawEditorRef?.current?.beautify()}
                        >
                          Beautify
                        </Button>
                      )}
                      {generators.enabled && body.type == ContentType.JSON ? (
                        <Button
                          className="secondary-button"
                          variant="outlined"
                          onClick={handleBodyModeChange}
                          disabled={hasErrors || body.string == ""}
                        >
                          {bodyMode === HTTPBodyMode.PLAIN ? "Edit in Structured View..." : "Edit in Raw View..."}
                        </Button>
                      ) : (
                        (body.type == ContentType.XML ||
                          body.type == ContentType.HTML ||
                          body.type == ContentType.TEXT) && (
                          <Dropdown.Button
                            type="default"
                            icon={<CaretDownOutlined style={{ fontSize: "20px" }} />}
                            trigger={["click"]}
                            disabled={hasErrors}
                            menu={menu?.props}
                            onOpenChange={rawEditorOpenChangeHander}
                            onClick={rawEditorSaveHandler}
                          >
                            Save
                          </Dropdown.Button>
                        )
                      )}
                    </Box>
                  )}
                </Box>
              )}
              {NO_BODY_METHODS.includes(method) || body.type == ContentType.NO_CONTENT ? (
                <InfoMessage
                  title={
                    NO_BODY_METHODS.includes(method) ? `No body content since ${method} request` : "No body content"
                  }
                  description={""}
                />
              ) : (
                renderBodyModeEditor(bodyMode)
              )}
            </Box>
          )}
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        height: "100%",
        bgcolor: "white",
        marginTop: 1.5,
      }}
    >
      <Tabs
        style={{ height: "100%" }}
        type="card"
        tabPosition={"top"}
        tabBarGutter={0}
        activeKey={sectionKey}
        items={items}
        onChange={(key) => setSectionKey(key)}
      ></Tabs>
    </Box>
  );
};

export default HTTPMessage;
