import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Typography, Upload, Select, message } from 'antd';
import { UploadOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProjectTS, { GetAssetContentResponse, ProjectAsset, ProjectAssetStatus, ProjectAssetType } from '../../api/services/ProjectTS';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const ProjectAssetManager: React.FC = () => {
    const [assets, setAssets] = useState<ProjectAsset[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [assetName, setAssetName] = useState('');
    const [assetType, setAssetType] = useState<ProjectAssetType | undefined>();
    const [fileContent, setFileContent] = useState<string>('');
    const [viewContent, setViewContent] = useState<string>('');
    const assetTypeLabels: Record<ProjectAssetType, string> = {
        [ProjectAssetType.UNKNOWN_PROJECT_ASSET_TYPE]: "File type not specified",
        [ProjectAssetType.API_TEST_UTIL_FILE]: "API test util file",
        [ProjectAssetType.EXAMPLE_API_TEST_FILE]: "API test example file",
    };

    useEffect(() => {
        fetchAssets();
    }, []);

    const fetchAssets = async () => {
        const response = await ProjectTS.listProjectAssets({});
        setAssets(response.assets || []);
    };

    const handleDeleteAsset = async (assetId: number | undefined) => {
        if (!assetId) return;

        Modal.confirm({
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete this asset?',
            onOk: async () => {
                await ProjectTS.deleteProjectAsset({ assetId });
                message.success('Asset deleted successfully');
                setAssets((prev) => prev.filter((asset) => asset.assetId !== assetId));
            },
        });
    };

    const handleAddAsset = () => {
        setSelectedFile(null);
        setAssetName('');
        setAssetType(undefined);
        setIsModalOpen(true);
    };

    const handleFileChange = (file: File) => {
        setSelectedFile(file);
        setAssetName(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target?.result as string;
            setFileContent(content);
        };
        reader.readAsText(file);
    };

    const handleSaveAsset = async () => {
        if (!selectedFile || !assetName || !assetType) {
            message.error('Please fill all fields.');
            return;
        }

        const asset: ProjectAsset = {
            name: assetName,
            relativePath: selectedFile.name,
            content: fileContent,
            type: assetType,
            status: ProjectAssetStatus.ACTIVE,
        };

        await ProjectTS.upsertProjectAssets({ assets: [asset] });
        setIsModalOpen(false);
        fetchAssets();
    };

    const handleTypeChange = async (asset: ProjectAsset, newType: ProjectAssetType) => {
        asset.type = newType;
        await ProjectTS.upsertProjectAssets({ assets: [asset] });
        setAssets([...assets]);
        message.success('Asset type updated successfully');
    };

    const handleViewFile = async (assetId: number | undefined) => {
        if (!assetId) return;

        const response: GetAssetContentResponse = await ProjectTS.getAssetContent({ id: assetId });
        setViewContent(response.content || '');
        setIsViewModalOpen(true);
    };

    const columns = [
        {
            title: 'Asset Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Asset Type',
            key: 'type',
            render: (_: any, asset: ProjectAsset) => (
                <Select
                    value={asset.type}
                    onChange={(value) => handleTypeChange(asset, value as ProjectAssetType)}
                    style={{ width: '100%' }}
                >
                    {Object.entries(ProjectAssetType)
                        .filter(([key, value]) => value !== ProjectAssetType.UNKNOWN_PROJECT_ASSET_TYPE)
                        .map(([key, value]) => (
                            <Option key={value} value={value}>
                                {assetTypeLabels[value as ProjectAssetType]}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            key: 'view',
            width: 50,
            render: (_: any, asset: ProjectAsset) => (
                <Button
                    icon={<EyeOutlined />}
                    onClick={() => handleViewFile(asset.assetId)}
                    className="secondary-button"
                    type="primary"
                    size='small'
                />
            ),
        },
        {
            key: 'delete',
            width: 50,
            render: (_: any, asset: ProjectAsset) => (
                <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteAsset(asset.assetId)}
                    className='secondary-button'
                    size='small'
                />
            ),
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                <Button className="primary-button" type="primary" icon={<PlusOutlined />} onClick={handleAddAsset}>
                    Add Asset
                </Button>
            </div>
            <Typography.Text
                style={{
                    marginTop: 16,
                    padding: '12px 16px',
                    marginBottom: "2rem",
                    backgroundColor: '#f7f7f7', // Light gray background
                    borderRadius: '4px', // Rounded corners
                    border: '1px solid #d9d9d9', // Border to give it more definition
                    display: 'block', // Ensures it behaves as a block-level element
                    fontSize: '0.8rem', // Optional, adjust the text size
                    fontStyle: 'italic',
                }}
            >  <InfoCircleOutlined style={{ marginRight: 8, color: '#1890ff' }} />

                You can add files such as existing API tests in your codebase, support classes that are used by tests etc. which will be used for generating scripts that can be used directly in your codebase.
            </Typography.Text>            <Table columns={columns} dataSource={assets} rowKey="assetId" />
            {/* Add Asset Modal */}
            <Modal
                title="Add Asset"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="save"
                        className="primary-button"
                        type="primary"
                        onClick={handleSaveAsset}
                    >
                        Save
                    </Button>,
                ]}
            >
                <div style={{ marginBottom: 16 }}>
                    <Typography.Text>Upload File:</Typography.Text>
                    <Upload
                        beforeUpload={(file) => {
                            handleFileChange(file);
                            return false; // Prevent auto-upload
                        }}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </div>
                <div style={{ margin: '16px 0' }}>
                    <Typography.Text>Asset Name:</Typography.Text>
                    <Typography.Paragraph>{assetName}</Typography.Paragraph>
                </div>
                <div>
                    <Typography.Text>Asset Type:</Typography.Text>
                    <Select
                        value={assetType}
                        onChange={(value) => setAssetType(value as ProjectAssetType)}
                        style={{ width: '100%' }}
                    >
                        {Object.entries(ProjectAssetType)
                            .filter(([key, value]) => value !== ProjectAssetType.UNKNOWN_PROJECT_ASSET_TYPE)
                            .map(([key, value]) => (
                                <Option key={value} value={value}>
                                    {assetTypeLabels[value as ProjectAssetType]}
                                </Option>
                            ))}
                    </Select>
                </div>
            </Modal>

            {/* View File Modal */}
            <Modal
                title="File Content"
                open={isViewModalOpen}
                onCancel={() => setIsViewModalOpen(false)}
                footer={[
                    <Button key="close" onClick={() => setIsViewModalOpen(false)}>
                        Close
                    </Button>,
                ]}
            >
                <pre style={{ maxHeight: 300, overflowY: 'auto' }}>{viewContent}</pre>
            </Modal>
        </div>
    );
};

export default ProjectAssetManager;