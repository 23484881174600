import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Steps, Button } from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Treatments, { UserJourneyType } from '../api/services/Treatments';
import "./UserJourneyWizard.css";
import GuideModal from './GuideModal';

const { Step } = Steps;

interface UserJourneyWizardProps {
    userJourney: UserJourneyType;
    onClose: () => void;
}

export interface UserJourneyWizardRef {
    refresh: () => void;
}

const UserJourneyWizard = forwardRef<UserJourneyWizardRef, UserJourneyWizardProps>(
    ({ userJourney, onClose }, ref) => {
        const [steps, setSteps] = useState<{ name: string; completed: boolean }[]>([]);
        const [loading, setLoading] = useState(true);
        const [isGuideModalVisible, setIsGuideModalVisible] = useState<boolean>(false);
        const [helpTopicToView, setHelpTopicToView] = useState<string | undefined>();
        const [helpSectionToView, setHelpSectionToView] = useState<string>("Test Studio");

        // Function to fetch and update steps
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await Treatments.listCompletedUserActions({
                    userJourneyType: userJourney,
                });

                const stepsMap: Record<UserJourneyType, { name: string; completed: boolean }[]> = {
                    [UserJourneyType.EXPORT_TEST_USER_JOURNEY]: [
                        { name: 'Setup Org', completed: true },
                        { name: 'Create Project', completed: response.exportTestActions?.createProject || false },
                        { name: 'Capture Session', completed: response.exportTestActions?.captureSession || false },
                        { name: 'Create Test', completed: response.exportTestActions?.createTest || false },
                        { name: 'Export Test', completed: response.exportTestActions?.exportTest || false },
                    ],
                    [UserJourneyType.CREATE_TEST_SCENARIO_USER_JOURNEY]: [
                        { name: 'Setup Org', completed: true },
                        { name: 'Create Project', completed: response.createTestScenariosActions?.createProject || false },
                        { name: 'Set Project Description', completed: response.createTestScenariosActions?.setProjectDescription || false },
                        { name: 'Define Personas', completed: response.createTestScenariosActions?.definePersonas || false },
                        { name: 'Create Epic', completed: response.createTestScenariosActions?.createEpic || false },
                        { name: 'Create User Story', completed: response.createTestScenariosActions?.createUserStory || false },
                        { name: 'Create Test Scenario', completed: response.createTestScenariosActions?.createTestScenario || false },
                    ],
                    [UserJourneyType.GENERATE_API_TESTS_USER_JOURNEY]: [
                        { name: 'Setup Org', completed: true },
                        { name: 'Create Project', completed: response.generateApiTestsActions?.createProject || false },
                        { name: 'Upload OpenAPI spec', completed: response.generateApiTestsActions?.uploadOpenapiSpec || false },
                        { name: 'Generate Tests', completed: response.generateApiTestsActions?.generateTests || false },
                    ],
                    [UserJourneyType.UNKNOWN_USER_JOURNEY_TYPE]: [],
                };

                let stepsData = stepsMap[userJourney];

                // Propagate `completed` backward
                for (let i = stepsData.length - 1; i >= 0; i--) {
                    if (stepsData[i].completed && i > 0) {
                        stepsData[i - 1].completed = true;
                    }
                }

                if (stepsData[stepsData.length - 1].completed) {
                    onClose();
                }

                setSteps(stepsData);
            } catch (error) {
                console.error('Failed to fetch user journey actions:', error);
            } finally {
                setLoading(false);
            }
        };

        // Expose the refresh function to parent
        useImperativeHandle(ref, () => ({
            refresh: () => {
                fetchData();
            },
        }));

        useEffect(() => {
            fetchData();
        }, [userJourney]);

        const currentStepIndex = steps.findIndex((step) => !step.completed) - 1;

        const closeGuideModal = () => {
            setIsGuideModalVisible(false);
            setHelpTopicToView(undefined);
        };

        useEffect(() => {
            if (helpTopicToView && helpSectionToView) {
                setIsGuideModalVisible(true);
            }
        }, [helpTopicToView, helpSectionToView]);

        const handleShowGuide = (stepName: string) => {
            if (stepName === "Create Project") {
                setHelpSectionToView("Admin");
                setHelpTopicToView("Create project");
            } else if (stepName === "Capture Session") {
                setHelpSectionToView("Sessions Tab");
                setHelpTopicToView("Capture manual test session");
            } else if (stepName === "Create Test") {
                setHelpSectionToView("Sessions Tab");
                setHelpTopicToView("Create test from session");
            } else if (stepName === "Export Test") {
                setHelpSectionToView("Test Studio");
                setHelpTopicToView("Export as a script");
            } else if (stepName === "Upload OpenAPI spec") {
                setHelpSectionToView("APIs Tab");
                setHelpTopicToView("Configure OpenAPI Spec");
            } else if (stepName === "Generate Tests") {
                setHelpSectionToView("APIs Tab");
                setHelpTopicToView("Generate API tests");
            }
        };

        return (
            loading ? (
                <div></div>
            ) : (
                <div style={{ position: 'relative', paddingTop: '22px', background: '#fff', borderRadius: '7px', textAlign: 'center' }}>
                    <Button
                        icon={<CloseOutlined />}
                        style={{ position: 'absolute', top: '8px', right: '8px', border: 'none', boxShadow: 'none' }}
                        onClick={onClose}
                    />

                    <Steps
                        current={currentStepIndex === -1 ? steps.length : currentStepIndex}
                        progressDot
                        direction="horizontal"
                        className="custom-progress-dot-steps"
                    >
                        {steps.map((step, index) => (
                            <Step
                                key={index}
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <a
                                            onClick={() => handleShowGuide(step.name)}
                                            style={{
                                                textDecoration: 'none',
                                                color: step.completed ? 'var(--primary-color)' : '#bfbfbf',
                                                cursor: step.completed ? 'pointer' : 'default',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {step.name}
                                        </a>
                                        {!step.completed && (
                                            <QuestionCircleOutlined
                                                style={{ marginLeft: '8px', color: '#bfbfbf', cursor: 'pointer' }}
                                                onClick={() => handleShowGuide(step.name)}
                                            />
                                        )}
                                    </div>
                                }
                            />
                        ))}
                    </Steps>
                    <GuideModal
                        visible={isGuideModalVisible}
                        onClose={closeGuideModal}
                        componentSelection={helpSectionToView}
                        topicSelection={helpTopicToView}
                    />
                </div>
            )
        );
    }
);

export default UserJourneyWizard;