import { QualifiedOperation } from "../api/services/Common";
import { TypedValue, TypedValueOrList } from "../models/typed/models";
import { AttributeLocator, OperationLocator } from "../api/services/Guards";
import { IQuery } from "../models/explore";
import { Primitive } from "../models/basic/model";
import { MenuProps } from "antd";
import { ContinuationState } from "../models/overlays";
import { TestScenarioSimple } from "../api/services/Scenarios";
import { IFieldUpdateRule } from "../test-generation/models";

export const CONTENT_TYPE = "content-type";
export const NO_BODY_METHODS = ["GET", "HEAD", "OPTIONS"];

export const emptyRawRequest: IRawRequest = {
  body: {
    textBody: "",
  },
  headers: {},
  httpMethod: "",
  queryParams: {},
};

export const emptyRawResponse: IRawResponse = {
  body: {
    textBody: "",
  },
  headers: {},
  responseCode: 200,
};

export interface ITestGenerationConfig {
  customPrompt?: string;
  generateCategories: GenerateTestCategory[];
  testSuiteName: string;
  customRulePrompts: number[];
  // Id of the test to run for setting up the context for the API test.
  prerunTestId?: string;
  fieldUpdateRules: IFieldUpdateRule[];
}

export interface GenerateApiTestsRequest {
  operationId: number;
  config?: ITestGenerationConfig;
}

export interface GenerateApiTestsResponse {}

export interface UpdateLinkedScenariosForTestRequest {
  testName: ITestName;
  testType: AwareTestType;
  linkedScenarioIds: number[];
}

export interface UpdateLinkedScenariosForTestResponse {}

export interface CommonExportConfig {
  includeRequestLogs: boolean;
  includeResponseLogs: boolean;
  includeStepComments:boolean;
  additionalPrompt: string;
  useProjectAssetsForCustomOutput: boolean;
}

export interface ExportConfig {
  exportFormat: TestExportFormat;
  commonExportConfig: CommonExportConfig;
}

export interface IPrepareTestExportRequest {
  testId?: string;
  testName?: ITestName;
  exportConfig: ExportConfig;
}

export interface IPrepareTestExportResponse {
  id: string;
}

export interface ListLinkedScenariosForTestRequest {
  testName: ITestName;
  testType: AwareTestType;
}

export interface ListLinkedScenariosForTestResponse {
  scenarios: TestScenarioSimple[];
}

export interface IGetGeneratedScriptRequest {
  prompt: string;
  availableVariables: string[];
}
export interface IGetGeneratedScriptResponse {
  script: string;
  nickname: string;
}

export interface IEvaluateAssertionsRequest {
  executionRecords: ITestStepExecutionRecord[];
  variables: { [key: string]: TypedValueOrList };
  assertions: ITestAssertion[];
}

export interface IEvaluateAssertionResponse {
  results: TestAssertionResult[];
  continuationState: ContinuationState;
}

export interface IListRequestIdsForContextRequest {
  requestFetchQuery: IRequestFetchQuery;
}

export interface IListFieldVocabsRequest {
  requestFetchQuery: IRequestFetchQuery;
}

export interface IListFieldVocabsResponse {
  fieldToVocabs: { [key: string]: { values: TypedValue[] } };
}

export interface IListScriptsRequest {}

export interface IListScriptsResponse {
  scripts: ScriptWithMetadata[];
}

export type ScriptWithMetadata = {
  id: number;
  name: string;
  description: string;
  script: string;
};

export interface RunJsScriptRequest {
  script: string;
  environment?: string;
  variableValues?: { [key: string]: TypedValueOrList };
}

export interface RunJsScriptResponse {
  result: string;
  error?:string;
}
export interface IGetRequestObjectRequest {
  requestId: string;
  operation: QualifiedOperation;
}

export interface IGenerateUiScriptRequest {
  sessionId: string;
  prompt?: string;
  outputFormat: UiScriptOutputFormat;
}

export interface IGenerateUiScriptResponse {
  script: string;
}

export interface IGetAssertionHashRequest {
  assertion: ITestAssertion;
}

export interface IGetAssertionHashResponse {
  hash: string;
}

export interface IGetRequestObjectResponse {
  requestObject: IRequestObject;
}

export interface IGenerateTestRequestRequest {
  requestConfig: IRequestConfig;
  variableValues: { [key: string]: TypedValueOrList };
  environment: string;
}

export interface IGenerateTestRequestResponse {
  request: IRawRequest;
}

export interface IGetAwareApiTestRequest {
  testName?: ITestName;
  invocationId?: string;
  testId?: string;
}

export interface IGetAwareApiTestResponse {
  testConfig: IAwareApiTest;
}

export interface IUpdateTestCaseStatusRequest {
  testIds: string[];
  testType?: string;
  newStatus: TestCaseStatus;
}

export interface IGetEndpointForOperationRequest {
  environment: string;
  operation: QualifiedOperation;
}

export type RunTestsRequest = {
  testCaseSelector?: ITestCaseSelector;
  rawTestStepExecution?: TestStepExecution;
  testCaseList?: ITestCaseList;
  environment?: string;
};

export type RunTestsResponse = {
  rawResponse?: IRawResponse;
  invocationBatchId?: string;
  traceId?: string;
  errorReport?: TestStepExecutionErrorReport;
};

export interface TestStepExecutionErrorReport {
  errorType?: string;
  errorDescription?: string;
  sutEndpoint?: string;
}

export interface ITestCaseList {
  testCases: ITestName[];
  testType: AwareTestType;
}

export interface ISaveTestRequest {
  testConfig: IAwareApiTest;
}

export interface IListTestSuitesRequest {
  type: AwareTestType;
}

export interface IListTestSuitesResponse {
  suites: string[];
  testSuites: TestSuiteNameAndId[];
}

export interface IListTestCasesRequest {
  testSuiteRegex: string;
  testType: AwareTestType;
}

export interface IListTestCasesResponse {
  testCases: ITestName[];
}

export interface IListTestStepsFromTestRequest {
  testName: ITestName;
  testType: AwareTestType;
}

export interface IListTestStepsFromTestResponse {
  steps: TestStep;
}

export interface IListTestCasesWithRunDetailsRequest {
  testSuite?: string;
  query?: TestCaseQuery;
}

export interface TestCaseQuery {
  operationId?: number;
  testCreationSource?: TestCreationSource;
  relation?: OperationToTestCaseRelation;
}

export enum OperationToTestCaseRelation {
  UNKNOWN_OPERATION_TO_TEST_CASE_RELATION = "UNKNOWN_OPERATION_TO_TEST_CASE_RELATION",
  IS_OPERATION_UNDER_TEST = "IS_OPERATION_UNDER_TEST",
  INCLUDED_IN_TEST = "INCLUDED_IN_TEST",
}

export enum TestCreationSource {
  UNKNOWN_CREATION_SOURCE = "UNKNOWN_CREATION_SOURCE",
  USER_AUTHORED = "USER_AUTHORED",
  AI_GENERATED = "AI_GENERATED",
}

export interface IListTestInvocationResultsRequest {
  invocationIds?: string[];
  batchInvocationId?: string;
}

export interface IListTestInvocationResultsResponse {
  invocationResults: ITestInvocationResult[];
}

export interface ITestInvocationResult {
  invocationId: string;
  testName?: ITestName;
  parameterizedRowString?: string;
  result: ITestExecutionResult;
  state: TestExecutionState;
}

export enum ScriptGenJobStatus {
  UNKNOWN_SCRIPT_GEN_JOB_STATUS = "UNKNOWN_SCRIPT_GEN_JOB_STATUS",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum TestExecutionState {
  UNKNOWN_TEST_EXECUTION_STATE = "UNKNOWN_TEST_EXECUTION_STATE",
  ENQUEUED_TO_EXECUTE = "ENQUEUED_TO_EXECUTE",
  EXECUTION_IN_PROGRESS = "EXECUTION_IN_PROGRESS",
  EXECUTION_COMPLETED = "EXECUTION_COMPLETED",
  EXCEPTION_IN_TEST_RUN = "EXCEPTION_IN_TEST_RUN",
  SKIPPED_EXECUTION = "SKIPPED_EXECUTION",
}

export interface ITestExecutionResult {
  awareApiTestExecutionResult: AwareApiTestExecutionResult;
}

export interface AwareApiTestExecutionResult {
  assertionResults: TestAssertionResult[];
}

export enum TestAssertionEvaluation {
  UNKNOWN_ASSERTION_RESULT = "UNKNOWN_ASSERTION_RESULT",
  PASSED = "PASSED",
  FAILED = "FAILED",
}

export enum TestCaseStatus {
  UNKNOWN_TEST_CASE_STATUS = "UNKNOWN_TEST_CASE_STATUS",
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  SUGGESTED = "SUGGESTED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
}

export enum TestParameterizeStatus {
  UNKNOWN_TEST_PARAMETERIZE_STATUS = "UNKNOWN_TEST_PARAMETERIZE_STATUS",
  IS_PARAMETERIZED = "IS_PARAMETERIZED",
  NOT_PARAMETERIZED = "NOT_PARAMETERIZED",
}

export interface TestAssertionResult {
  leftActualValue: TypedValueOrList;
  rightActualValue: TypedValueOrList;
  evaluation: TestAssertionEvaluation;
  assertionId: string;
}

export interface IListTestCasesWithRunDetailsResponse {
  testCases: ITestCaseWithRunData[];
}

export interface ITestCaseWithRunData {
  testCase: ITestNameWithId;
  lastRunTimestamp: string;
  latestInvocations: IInvocationDetail[];
  status: TestCaseStatus;
  parameterizeStatus: TestParameterizeStatus;
}

export interface ParameterizedTestRunData {
  paramsString: string;
  paramsHash: string;
  lastRunTimestamp: string;
  latestInvocations: IInvocationDetail[];
}

export interface ListParameterizedTestRunsRequest {
  testId: string;
}

export interface ListParameterizedTestRunsResponse {
  testId: string;
  parameterizedTestRuns: ParameterizedTestRunData[];
}

export interface IInvocationDetail {
  invocationId: string;
  successAssertions: number;
  failureAssertions: number;
  timestamp: string;
  state: TestExecutionState;
}

export interface ICreateTestSuiteRequest {
  name: string;
  type: AwareTestType;
}

export interface ScriptConfig {
  script: string;
  nickname: string;
}

export interface IAwareApiTest {
  testName: ITestName;
  rootSteps: TestStep[];
  assertions: ITestAssertion[];
  scriptConfigs?: Record<string, ScriptConfig>;
  vocabConfigs?: Record<string, IVocabularyConfig>;
  parameterizeConfig?: ParameterizeConfig;
}

export interface ParameterizeConfig {
  rows: ParamRow[];
}

export interface ParamRow {
  values: Record<string, TypedValueOrList>;
}

export interface ITestAssertion {
  leftExpression: TestExpression;
  rightExpression: TestExpression;
  assertionOperation: TestAssertionOperation;
  assertionId: string;
  source?: AssertionSource;
}

export enum AssertionSource {
  UNKNOWN_ASSERTION_SOURCE = "UNKNOWN_ASSERTION_SOURCE",
  MANUAL = "MANUAL",
  SUGGESTED = "SUGGESTED",
}

export type TestExpression = {
  fixedValues?: TypedValueOrList;
  variable?: string;
  valueLocator?: ValueLocator;
};

export type ValueLocator = {
  stepName: string;
  requestValueLocator?: IRequestValueLocator;
  responseValueLocator?: IResponseValueLocator;
  attributeKey?: string;
  // The operation context for extracting the value (which path prefix - if not present, assumes root operation. Must be present for attributeKey value locators).
  operation?: OperationLocator;
  evaluationModifier?: ValueEvaluationModifier;
};

export type IRequestValueLocator = {
  bodyValueLocator?: BodyValueLocator;
  headerKey?: string;
  queryParamKey?: string;
  templateParamKey?: string;
};

export type IResponseValueLocator = {
  bodyValueLocator?: BodyValueLocator;
  headerKey?: string;
  responseCode?: boolean;
};

export type BodyValueLocator =
  | { jsonFieldPath?: string }
  | { textField: boolean }
  | { htmlFieldPath: string }
  | { xmlFieldPath: string }
  | { formDataKey: string }
  | { formDataUrlencodedKey: string };

export enum ValueEvaluationModifier {
  VALUE = 1,
  SIZE,
}

export enum TestAssertionSide {
  LEFT,
  RIGHT,
}

export enum TestAssertionOperation {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  CONTAINS = "CONTAINS",
  IS_EMPTY = "IS_EMPTY",
  IS_NOT_EMPTY = "IS_NOT_EMPTY",
}

export enum TestAssertionOperationType {
  VALUE = 1,
  EMPTY,
  NULL,
}

export const TestAssertionOperationTypeMapping: Record<string, TestAssertionOperationType> = {
  EQUALS: TestAssertionOperationType.VALUE,
  NOT_EQUALS: TestAssertionOperationType.VALUE,
  GREATER_THAN: TestAssertionOperationType.VALUE,
  LESS_THAN: TestAssertionOperationType.VALUE,
  IS_NULL: TestAssertionOperationType.NULL,
  IS_NOT_NULL: TestAssertionOperationType.NULL,
  STARTS_WITH: TestAssertionOperationType.VALUE,
  ENDS_WITH: TestAssertionOperationType.VALUE,
  CONTAINS: TestAssertionOperationType.VALUE,
  IS_EMPTY: TestAssertionOperationType.EMPTY,
  IS_NOT_EMPTY: TestAssertionOperationType.EMPTY,
};

export type TestStep = {
  name: string;
  internalEndpointConfig?: IInternalEndpointConfig;
  externalEndpoint?: string;
  requestConfig: IRequestConfig;
  responseConfig: IResponseConfig;
  // TODO Implement
  callTreeConfig?: ICallTreeConfig;
};

export interface ITestName {
  testId?: string;
  name: string;
  suite: string;
}

export interface ITestNameWithId {
  name: string;
  suite: string;
  testId: string;
}

export interface TestSuiteNameAndId {
  name: string;
  id: number;
}

export interface ITestCaseSelector {
  testCaseRegex: string;
  testSuiteRegex: string;
  testType: AwareTestType;
}

export enum AwareTestType {
  LOAD_TEST = "LOAD_TEST",
  FS_TEST = "FS_TEST",
}

export enum TestExportFormat {
  UNKNOWN_TEST_EXPORT_FORMAT = "UNKNOWN_TEST_EXPORT_FORMAT",
  JEST = "JEST",
  CYPRESS = "CYPRESS",
  SELENIUM_JS = "SELENIUM_JS",
  SELENIUM_JAVA = "SELENIUM_JAVA",
  REST_ASSURED = "REST_ASSURED",
  PLAYWRIGHT = "PLAYWRIGHT",
  TESTNG = "TESTNG",
  MOCHA = "MOCHA",
  PYTEST = "PYTEST",
  NUNIT = "NUNIT",
}

export enum UiScriptOutputFormat {
  UNKNOWN_UI_SCRIPT_OUTPUT_FORMAT = "UNKNOWN_UI_SCRIPT_OUTPUT_FORMAT",
  PLAYWRIGHT = "PLAYWRIGHT",
  SELENIUM = "SELENIUM",
  CYPRESS = "CYPRESS",
}

export type TestStepExecution = {
  internalEndpointConfig?: IInternalEndpointConfig;
  externalEndpoint?: string;
  rawRequest?: IRawRequest;
};

export interface IInternalEndpointConfig {
  environment: string;
  operation: QualifiedOperation;
  preferredEndpoint: string;
}

export interface IRequestObject {
  id: string;
  rawRequest: IRawRequest;
}

export interface ITemplatePathParams {
  keyValueMap: {
    [key: string]: string;
  };
}

export interface IHttpFormDataBody {
  keyValueMap: {
    [key: string]: string;
  };
}

export interface IHttpFormUrlencodedBody {
  keyValueMap: {
    [key: string]: string;
  };
}

export interface IRawMessage {
  headers: {
    [key: string]: string;
  };
  body: IMessageBody;
}

export interface IRawRequest extends IRawMessage {
  httpMethod: string;
  queryParams: {
    [key: string]: string;
  };
  templatePathParams?: ITemplatePathParams;
}

export interface IRawResponse extends IRawMessage {
  responseCode: number;
}

export interface IMessageBody {
  jsonBody?: string;
  textBody?: string;
  htmlBody?: string;
  xmlBody?: string;
  httpFormDataBody?: IHttpFormDataBody;
  httpFormUrlencodedBody?: IHttpFormUrlencodedBody;
}

export interface IRequestConfig {
  headerConfig: IRequestHeaderConfig;
  jsonRequestBodyConfig?: IJsonMessageConfig;
  textRequestBodyConfig?: ITextMessageConfig;
  htmlRequestBodyConfig?: IHtmlMessageConfig;
  xmlRequestBodyConfig?: IXmlMessageConfig;
  formDataRequestBodyConfig?: IFormDataMessageConfig;
  formDataUrlencodedRequestBodyConfig?: IFormDataUrlencodedMessageConfig;
  messageBodyConfig?: IMessageBodyConfig;
  variableRecordConfigs: VariableRecordConfig[];
  httpMethod: string;
  queryPathParams?: { [key: string]: FieldConfig };
  templatePathParams?: { [key: string]: FieldConfig };
}

export interface IResponseConfig {
  variableRecordConfigs: VariableRecordConfig[];
}

export interface ICallTreeConfig {
  variableRecordConfigs: IOperationContextualVariableRecordConfig[];
}

export interface IRequestHeaderConfig {
  headerFields: { [key: string]: FieldConfig };
}

export interface IJsonMessageConfig {
  fieldConfigs: { [key: string]: FieldConfig };
}

export interface IXmlMessageConfig {
  fieldConfig: FieldConfig;
}

export interface IHtmlMessageConfig {
  fieldConfig: FieldConfig;
}
export interface ITextMessageConfig {
  fieldConfig: FieldConfig;
}

export interface IFormDataMessageConfig {
  fieldConfigs: { [key: string]: FieldConfig };
}

export interface IFormDataUrlencodedMessageConfig {
  fieldConfigs: { [key: string]: FieldConfig };
}

export interface IMessageBodyConfig {
  message: string;
}

export interface IOperationContextualVariableRecordConfig {
  operationLocator: OperationLocator;
  payloadLocator: PayloadLocator;
  config: VariableRecordConfig;
}

export type FieldConfig = {
  variableExpression?: { expression: string };
  scriptConfig?: ScriptConfig;
  vocabConfig?: IVocabularyConfig;
  fieldType: FieldDataType;
};

export enum FieldDataType {
  STRING = "STRING",
  FLOAT = "FLOAT",
  INT = "INT",
  BOOl = "BOOl",
}

export interface IStep {
  id: string;
  name?: string;
  timestamp?: number;
  refTraceId?: string;
  context: StepContext;
}

export interface StepContext {
  internalContext?: IInternalStepContext;
  externalContext?: IExternalStepContext;
  testStep?: TestStep;
}

export interface IVocabularyConfig {
  numberOfChoices: number;
  options: TypedValue[];
  nickname: string;
}

export interface IHTTPBodyTreeNode {
  key: string;
  modelPath: string;
  attributeKey: string;
  attributeValue: any;
  children: IHTTPBodyTreeNode[];
}

export interface IDrawerContext {
  // Only applicable for Request / Response ContextTypes
  section: HTTPMessageSection;
  key: string;
  value: any;
  modelKey: string;
  bodyPath?: string;
  headerKey?: string;
  queryParamKey?: string;
  pathParamKey?: string;
  responseCode?: string;
}

export interface IDecoratedDrawerContext extends IDrawerContext {
  contextType: ContextType;
  operationLocator: OperationLocator;
}

export interface IInternalStepContext extends IRequestFetchQuery {
  endpoint?: string;
}

export interface IExternalStepContext {
  template?: IRequestTemplate;
  uri: string;
}

export interface IRequestFetchQuery {
  query?: IQuery;
  context?: IAttributeValueLocator;
  operation: QualifiedOperation;
}

export interface IAttributeValueLocator {
  attribute: AttributeLocator;
  attribValue: TypedValue;
}

export interface IEditableField {
  key: string;
  value: Primitive;
}

export interface IGenerator {
  type: GeneratorType;
  value: any;
  // This is used for display of the generator in raw format / in the textbox for the field in structured format. In the case of freeform, displayValue = value;
  displayValue: string;
  dataType: FieldDataType;
}

export interface IGeneratorKV {
  [key: string]: IGenerator;
}

export interface IVariable {
  contextType: ContextType;
  section: HTTPMessageSection;
  name: string;
  locator: string;
  value: TypedValueOrList;
  // Present only if the variable is referring to a call tree contextual.
  operationLocator?: OperationLocator;
}

export interface CTMetaData {
  value: string;
  regex: string;
  editorMode: EditorMode;
}

export enum GenerateTestCategory {
  UNKNOWN_GENERATE_TES_CATEGORY = 0,
  FUNCTIONAL = 1,
  SECURITY = 2,
  VALIDATIONS = 3,
  OPEN_ENDED = 4,
}

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
}

export enum MainTab {
  REQUEST_GENERATOR = "Request Generator",
  TEST_REQUEST = "Test Request",
  RESPONSE = "Response",
  ATTRIBUTES = "Attributes",
}

export enum TreeLoadState {
  NOT_STARTED,
  LOADING,
  COMPLETE,
  NOT_AVAILABLE,
}

export enum VariableStoreTab {
  NEW,
  AVAILABLE,
}

export enum GeneratorType {
  FREEFORM,
  VOCABULORY,
  SCRIPT,
}

// The context type defining the context in which variables / assertions are being operated on.
export enum ContextType {
  REQUEST,
  RESPONSE,
  ATTRIBUTES,
}

export enum HTTPMessageSection {
  HEADER = "HEADER",
  BODY = "BODY",
  PATH_PARAMS = "PATH_PARAMS",
  QUERY_PARAMS = "QUERY_PARAMS",
  RESPONSE_CODE = "RESPONSE_CODE",
}

export enum HTTPBodyMode {
  PLAIN,
  FORM,
  KV,
}

export enum ContentType {
  NO_CONTENT = "NO_CONTENT",
  JSON = "JSON",
  XML = "XML",
  TEXT = "TEXT",
  HTML = "HTML",
  FORM_URL_ENCODED = "FORM_URL_ENCODED",
  MULTIPART_FORM = "MULTIPART_FORM",
}

export const ContentTypeMetadata: {
  [type: string]: CTMetaData;
} = {
  [ContentType.NO_CONTENT]: { value: "no-content", regex: "no-content", editorMode: "text" },
  [ContentType.JSON]: {
    value: "application/json",
    regex:
      "^(application\\/(?:json|x-amz-json-\\d+\\.\\d+|json\\+protobuf|vnd\\.salesforce\\.object\\+json|vnd\\.api\\+json|vnd\\.microsoft\\.azure-query\\+json|x-ndjson|vnd\\.kafka\\.json\\.v\\d+|vnd\\.mqtt\\+json|vnd\\.payment\\.x\\.\\d+))$",
    editorMode: "json",
  },
  [ContentType.XML]: {
    value: "application/xml",
    regex: "^(application\\/(?:xml|rss\\+xml|atom\\+xml|soap\\+xml|x-sitemap\\+xml|x-font-xml|x-javascript-mojo-xml))$",
    editorMode: "xml",
  },
  [ContentType.TEXT]: {
    value: "text/plain",
    regex: "^(text\\/(?:plain|x-log|csv|tab-separated-values|x-markdown|x-latex))$",
    editorMode: "text",
  },
  [ContentType.HTML]: {
    value: "text/html",
    regex:
      "^(application\\/(?:html|x-html-fragment|vnd\\.wappalyzer\\+html|vnd\\.browser\\+html|x-embedded-html|rss\\+html))$",
    editorMode: "html",
  },
  [ContentType.FORM_URL_ENCODED]: {
    value: "application/x-www-form-urlencoded",
    regex: "application/x-www-form-urlencoded",
    editorMode: "text",
  },
  [ContentType.MULTIPART_FORM]: { value: "multipart/form-data", regex: "multipart/form-data", editorMode: "text" },
};

export type EditorMode = "json" | "xml" | "text" | "html";

export interface IAssertionSuggestion {
  assertion: ITestAssertion;
}

export interface IAssertionSuggestionGroup {
  groupName: string;
  groupDescription: string;
  suggestions: IAssertionSuggestion[];
}

export interface IListSuggestedAssertionsResponse {
  suggestionGroups: IAssertionSuggestionGroup[];
}

export interface IListSuggestedAssertionsRequest {
  testStepRecords?: ITestStepExecutionRecord[];
}

export interface ITestStepExecutionRecord {
  stepName: string;
  traceId?: string;
  rawRequest: IRawRequest;
  rawResponse: IRawResponse;
}

export interface IListSuggestedVariablesRequest {
  testStepRecords?: ITestStepExecutionRecord[];
  existingVariableNames: string[];
}

export interface VariableDefinition {
  definitionContext?: TestStepContext;
  variableRecordConfig?: VariableRecordConfig;
}

export interface IListSuggestedVariablesResponse {
  variableSuggestions?: VariableSuggestion[];
}

export interface IListRequestTemplatesRequest {}

export interface IListRequestTemplatesResponse {
  templates: IRequestTemplate[];
}

export interface IRequestTemplate {
  id: number;
  name: string;
  description: string;
  uri: string;
  request: IRawRequest;
}

export interface TestStepContext {
  stepName?: string;
  locator?: PayloadLocator;
}

export enum PayloadLocator {
  UNKNOWN_VARIABLE_LOCATOR = "UNKNOWN_VARIABLE_LOCATOR",
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
  ATTRIBUTES = "ATTRIBUTES",
}

export interface VariableUsageSuggestion {
  usageContext?: TestStepContext;
  usageSuggestionId: string;
  locator: VariableValueLocator;
  usagePrefix?: string;
}

export interface VariableRecordConfig {
  locator: VariableValueLocator;
  variableName?: string;
  currentValue?: TypedValueOrList;
}

export interface VariableValueLocator {
  bodyValueLocator?: BodyValueLocator;
  headerKey?: string;
  queryParamKey?: string;
  templateParamKey?: string;
}

export interface VariableSuggestion {
  suggestionId: string;
  variableDefinitionContext?: TestStepContext;
  variableRecordConfig?: VariableRecordConfig;
  variableUsageSuggestions?: VariableUsageSuggestion[];
}

export interface IMenu {
  props: MenuProps;
  handleOpen: (currentMenuContext: { open: boolean; context: IDrawerContext }) => void;
}

export interface HTTPMessageProps {
  message?: IRawRequest | IRawResponse;
  menu?: IMenu;
  method: string;
  loading: boolean;
  body: {
    string: string;
    setString: (body: string) => void;
    type: ContentType;
    setType: (contentType: ContentType) => void;
    isReadOnly: boolean;
  };
  tree: {
    body: IHTTPBodyTreeNode[];
    setBody: (bodyTree: IHTTPBodyTreeNode[]) => void;
    expandAll: boolean;
    expandParent: boolean;
  };
  generators: {
    enabled: boolean;
    body: IGeneratorKV;
    header: IGeneratorKV;
    query: IGeneratorKV;
    path: IGeneratorKV;
    setAll?: (section: HTTPMessageSection, generators: { [key: string]: IGenerator }) => void;
    set?: (key: string, section: HTTPMessageSection, generator: IGenerator) => void;
    remove?: (section: HTTPMessageSection, key: string) => void;
  };
  availableVariables: IVariable[];
  availableParameters: String[];
  availableEnvVariables: String[];
}

export interface NonGeneratorHTTPMessageProps {
  menu?: IMenu;
  isResponse: boolean;
}

export interface GeneratorHTTPMessageProps {
  menu?: IMenu;
}

export interface ReadonlyHTTPMessageProps {
  message?: IRawRequest | IRawResponse;
  isResponse: boolean;
}

interface RequestResponse {
  request?: IRawRequest;
  response?: IRawResponse;
}
