import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Table, Button, Modal, Input, Typography, Space } from 'antd';
import { addEnvironment, setEnvironmentVariable, removeEnvironmentVariable } from './projectSettingsSlice';
import { useAppSelector } from '../../app/hooks';
import { generateUniqueID } from '../../util';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const EnvironmentSettings: React.FC = () => {
  const dispatch = useDispatch();
  const tempVariables = useAppSelector(state => state.projectSettings.tempVariables);
  const [selectedEnv, setSelectedEnv] = useState<string>(Object.keys(tempVariables)[0] || '');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newEnvName, setNewEnvName] = useState<string>('');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewEnvName('');
  };

  const handleAddEnvironment = () => {
    if (newEnvName && !tempVariables[newEnvName]) {
      dispatch(addEnvironment(newEnvName));
      setSelectedEnv(newEnvName);
      handleCloseModal();
    }
  };

  const handleVariableChange = (variableId: string, value: string) => {
    console.log("Changing value", variableId + " " + value);
    const variable = tempVariables[selectedEnv].variables?.find(v => v.id === variableId);
    dispatch(setEnvironmentVariable({ envKey: selectedEnv, variableId, name: variable?.name ?? "", value }));
  };

  const handleVariableRename = (variableId: string, name: string) => {
    console.log("Changing name", variableId + " " + name);
    const value = tempVariables[selectedEnv].variables.find(v => v.id === variableId)?.value || '';
    dispatch(setEnvironmentVariable({ envKey: selectedEnv, variableId, name, value }));
  };

  const handleRemoveVariable = (variableId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this variable?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        dispatch(removeEnvironmentVariable({ envKey: selectedEnv, variableId }));
      },
    });
  };

  const handleAddVariable = () => {
    const newVarId = generateUniqueID();
    dispatch(setEnvironmentVariable({ envKey: selectedEnv, variableId: newVarId, name: '', value: '' }));
  };

  const columns = [
    {
      title: 'Variable Name',
      dataIndex: 'name',
      render: (text: string, record: any) => (
        <Input
          value={text}
          onChange={(e) => handleVariableRename(record.id, e.target.value)}
        />
      ),
    },
    {
      title: 'Variable Value',
      dataIndex: 'value',
      render: (text: string, record: any) => (
        <Input
          value={text}
          onChange={(e) => handleVariableChange(record.id, e.target.value)}
        />
      ),
    },
    {
      title: 'Actions',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => handleRemoveVariable(record.id)}
            className='secondary-button'
            icon={<DeleteOutlined />}
            size='small'
          />
        </Space>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '1em', borderBottom: '1px solid #e8e8e8' }}>
        <Tabs
          activeKey={selectedEnv}
          onChange={setSelectedEnv}
          style={{ flex: 1 }}
        >
          {Object.keys(tempVariables ?? {}).map(envKey => (
            <TabPane tab={envKey} key={envKey} />
          ))}
        </Tabs>
        <Button
          onClick={handleOpenModal}
          type="primary"
          className='primary-button'
          icon={<PlusOutlined />}
          style={{ marginLeft: 10 }}
        >
          Add Environment
        </Button>
      </div>

      <div style={{ flex: 1, padding: '1em', overflow: 'auto' }}>
        {selectedEnv && tempVariables[selectedEnv] && (
          <Table
            columns={columns}
            dataSource={tempVariables[selectedEnv].variables?.map((v: any) => ({ ...v }))}
            rowKey="id"
            pagination={false}
          />
        )}
      </div>

      {/* Add Variable Button */}
      <div style={{ padding: '1em', alignItems: 'flex-start' }}>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          onClick={handleAddVariable}
        >
          Add Variable
        </Button>
      </div>

      <Modal
        title="Add New Environment"
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button key="submit" className="primary-button" type="primary" onClick={handleAddEnvironment}>
            Add
          </Button>,
        ]}
      >
        <Input
          value={newEnvName}
          onChange={(e) => setNewEnvName(e.target.value)}
          placeholder="Environment Name"
        />
      </Modal>
    </div>
  );
};

export default EnvironmentSettings;