import React, { useEffect, useState } from "react";
import OOBEWizard from "./OOBEWizard"; // Import the OOBEWizard component
import LocalStorageManager from "../common/LocalStorageManager";
import QuickStartWizard from "./FirstTestWizard";
import { useAppDispatch } from "../app/hooks";
import { setSelectedProject } from "../features/commonSlice";
import ScenarioQuickStartWizard from "./ScenarioQuickStartWizard";

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const OOBEPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [isCreateTestModalVsible, setIsCreateTestModalVisible] = useState<boolean>(false);
    const [isCreateTestPlanModalVsible, setIsCreateTestPlanModalVisible] = useState<boolean>(false);
    const [flow, setFlow] = useState<string>("");
    const dispatch = useAppDispatch();

    useEffect(() => {
        let organizationId: string = LocalStorageManager.getOrganizationId();
        setIsModalVisible(!organizationId);
        if (organizationId) {
            setFlow("API_TEST");
        }
    }, []);

    useEffect(() => {
        const fetchProject = async () => {
            await dispatch(setSelectedProject(LocalStorageManager.getProjectId()));
        };
        fetchProject();
        if (flow === "API_TEST") {
            setIsCreateTestModalVisible(true);
        }
        if (flow === "EXPLORE") {
            window.location.href = BASE_APP_URL + "/sessions";
        }
    }, [flow]);

    const onCompleteQuickStartWizard = async (showTestPlanGuide: boolean) => {
        setIsCreateTestModalVisible(false);
        if (showTestPlanGuide) {
            handleFlowSelection("TEST_PLAN");
        } else {
            window.location.href = BASE_APP_URL + "/sessions";
        }
    };

    const onCompleteScenarioQuickStartWizard = async (showTestCreateQuickStart: boolean) => {
        setIsCreateTestPlanModalVisible(false);
        if (showTestCreateQuickStart) {
            handleFlowSelection("API_TEST");
        } else {
            window.location.href = BASE_APP_URL + "/scenarios";
        }
    };

    // Callback function to handle flow selection
    const handleFlowSelection = (selectedFlow: "API_TEST" | "TEST_PLAN" | "EXPLORE") => {
        setFlow(selectedFlow);
        if (selectedFlow === "API_TEST") {
            setIsCreateTestModalVisible(true);
        } else if (selectedFlow === "TEST_PLAN") {
            setIsCreateTestPlanModalVisible(true);
        }
        setIsModalVisible(false);
    };

    return (
        <div
            style={{
                backgroundColor: "var(--background-color)",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
            }}
        >
            {isModalVisible && (
                <OOBEWizard onFlowSelect={handleFlowSelection} />
            )}
            {flow === "API_TEST" && isCreateTestModalVsible && (
                <QuickStartWizard onComplete={onCompleteQuickStartWizard}></QuickStartWizard>
            )}
            {flow === "TEST_PLAN" && isCreateTestPlanModalVsible && (
                <ScenarioQuickStartWizard onClose={onCompleteScenarioQuickStartWizard}></ScenarioQuickStartWizard>
            )}
        </div>
    );
};

export default OOBEPage;