import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { Row, Col, Typography, Space } from 'antd';
import CopyableTextField from '../../common/CopyableTextField';

const GeneralSettings: React.FC = () => {
  const projectConfig = useAppSelector(state => state.projectSettings.projectConfig);
  const projectId = useAppSelector(state => state.common.selectedProject);

  if (!projectConfig) return null;

  return (
    <div>
      <Row gutter={[24, 24]} align="middle" style={{ marginBottom: '32px' }}>
        <Col span={6}>
          <Typography.Text style={{ fontSize: '1rem', lineHeight: '32px' }}>
            Project ID
          </Typography.Text>
        </Col>
        <Col span={18}>
          <CopyableTextField value={projectId} />
        </Col>
      </Row>
      <Row gutter={[24, 24]} align="middle" style={{ marginBottom: '32px' }}>
        <Col span={6}>
          <Typography.Text style={{ fontSize: '1rem', lineHeight: '32px' }}>
            Session Record API Key
          </Typography.Text>
        </Col>
        <Col span={18}>
          <CopyableTextField value={projectConfig.sessionRecordApiKey} />
        </Col>
      </Row>
      <Row gutter={[24, 24]} align="middle" style={{ marginBottom: '32px' }}>
        <Col span={6}>
          <Typography.Text style={{ fontSize: '1rem', lineHeight: '32px' }}>
            Data API Key
          </Typography.Text>
        </Col>
        <Col span={18}>
          <CopyableTextField value={projectConfig.apiKey} />
        </Col>
      </Row>
    </div>
  );
};

export default GeneralSettings;