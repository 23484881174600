import React, { useState } from "react";
import { Modal, Input, Typography, Button, Space, message } from "antd";
import { Project } from "../../../api";

const AddProject = ({ open, handleClose, org }) => {
  const [projectValue, setProjectValue] = useState({
    project_name: "",
  });

  const onProjectAddHandler = async () => {
    const response = await Project.add(projectValue);
    if (!response.error) {
      message.success("Project added successfully");
      handleClose();
    } else {
      console.error(response.error);
      message.error("Project adding failed");
    }
  };

  return (
    <Modal
      title="Add Project"
      visible={open}
      onCancel={handleClose}
      footer={null}
    >
      <Space direction="vertical" style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}>
        <Input
          placeholder="Project Name"
          value={projectValue.project_name}
          onChange={(event) =>
            setProjectValue({
              ...projectValue,
              project_name: event.target.value,
            })
          }
        />
      </Space>
      <div style={{ marginTop: "1rem", textAlign: "right" }}>
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" className="primary-button" onClick={onProjectAddHandler}>
            Add Project
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default AddProject;