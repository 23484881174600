import React from 'react';
import { Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

interface CopyableTextFieldProps {
  value: string | undefined;
}

const CopyableTextField: React.FC<CopyableTextFieldProps> = ({ value }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value + "");
      console.log('Text copied to clipboard:', value);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <Input
      value={value}
      readOnly
      suffix={
        <Tooltip title="Copy to clipboard">
          <CopyOutlined onClick={handleCopy} style={{ cursor: 'pointer' }} />
        </Tooltip>
      }
    />
  );
};

export default CopyableTextField;